import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { listGroupsDetails, updateGroup } from '../actions/groupActions'
import { GROUP_UPDATE_RESET } from '../constants/groupConstants'
import { useTranslation } from 'react-i18next';

import { variant_main } from '../constants/generalConstants'

const GroupEditScreen = ({ match, history }) => {
  const groupId = match.params.id

  const [name, setName] = useState('')
  const [order, setOrder] = useState(0)
  const [img, setImg] = useState('')
  const [keywords, setKeywords] = useState('')
  const [description, setDescription] = useState('')
  const [uploading, setUploading] = useState(false)

  const dispatch = useDispatch()

  const groupDetails = useSelector((state) => state.groupDetails)
  const { loading, error, group } = groupDetails

  const groupUpdate = useSelector((state) => state.groupUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = groupUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: GROUP_UPDATE_RESET })
      history.push('/admin/catsList')
    } else {
      if (!group.cat_name || group._id !== groupId) {
        dispatch(listGroupsDetails(groupId))
      } else {
        setName(group.cat_name)
        setOrder(group.cat_order)
        setImg(group.cat_img)
        setKeywords(group.cat_keywords)
        setDescription(group.cat_description)
      }
    }
  }, [dispatch, history, groupId, group, successUpdate])

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    setUploading(true)

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      const { data } = await axios.post('/api/upload', formData, config)

      setImg(data)
      setUploading(false)
    } catch (error) {
      console.error(error)
      setUploading(false)
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateGroup({
        _id: groupId,
        name,
        order,
        img,
        keywords,
        description,
      })
    )
  }
  const { t } = useTranslation();


  return (
    <>
      <Link to='/admin/catslist' className='btn btn-light my-3'>
        {t('Go Back')}
      </Link>
      <FormContainer>
        <h1>{t('Edit group')}</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='name'>
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control
                type='name'
                placeholder={t('Enter name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='order'>
              <Form.Label>{t('SOrder')}</Form.Label>
              <Form.Control
                type='number'
                placeholder={t('Enter Sorder')}
                value={order}
                onChange={(e) => setOrder(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='img'>
              <Form.Label>{t('Image')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('Enter image url')}
                value={img}
                className='ltr_text'
                onChange={(e) => setImg(e.target.value)}
              ></Form.Control>
              <Form.Control
                type="file"
                id='image-file'
                label={t('Choose File')}
                custom
                onChange={uploadFileHandler}
              ></Form.Control>
              {uploading && <Loader />}
            </Form.Group>

            <Form.Group controlId='keywords'>
              <Form.Label>{t('Keywords')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('Enter keywords')}
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='description'>
              <Form.Label>{t('Description')}</Form.Label>
              <Form.Control
                type='text'
                placeholder={t('Enter description')}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button type='submit' variant={variant_main}>
              {t('Update')}
            </Button>
          </Form>
        )}
      </FormContainer>
    </>
  )
}

export default GroupEditScreen
