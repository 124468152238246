import React from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown, Image } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { logout } from '../actions/userActions'


import { appName, appLogo } from '../constants/generalConstants';

const Header = () => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // const BName = useSelector((state) => state.BName)

  const selectedItemText = useSelector((state) => state.selectedItemText);
  //const { Bname } = selectedItem;


  const logoutHandler = () => {
    console.log('logout')
    dispatch(logout())
  }
  const { t } = useTranslation();

  const myAppLogo = '/assets/imgs/' + appLogo; 
  //console.log('myAppLogo:' + myAppLogo)

  const schema = localStorage.getItem(`${appName}_schema`)
  const schema_name = localStorage.getItem(`${appName}_schema_name`)
  return <header>
    <Navbar className="navbar-color" expand="lg" collapseOnSelect>
      <Container>
        <LinkContainer to='/'>
          <Navbar.Brand >
            <img thumbnail='true' src={JSON.parse(schema_name)?.logo} className='me-2 rounded-1' width="40" alt="App Logo" />
            {JSON.parse(schema_name)?.name}
          </Navbar.Brand>
          {/* <Navbar.Image src={myAppLogo} thumbnail alt="App Logo"></Navbar.Image> */}
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto flex-grow-1 flex-lg-row-reverse">
            {userInfo ? (
              <NavDropdown title={userInfo.usr_name} id='username'>
                <LinkContainer to='/profile'>
                  <NavDropdown.Item>{t('Profile')}</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler}>
                  {t('Logout')}
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <NavDropdown title='FuelPlus' id='Fuel'>
                <LinkContainer to='login'>
                  <NavDropdown.Item>{t('Sign In')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/busines'>
                  <NavDropdown.Item>{t('Replace Business')}</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )
            }
            {userInfo && userInfo.usr_role === 'admin' && (
              <NavDropdown title={t('General Admin')} id='adminmenu'>
                <LinkContainer to='/admin/userlist'>
                  <NavDropdown.Item>{t('Users')}</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}
            {userInfo && userInfo.usr_role === 'admin' && (
              <NavDropdown title={t('Reports')} id='reportmenu'>
                <LinkContainer to='/reports/month_custs_product'>
                  <NavDropdown.Item>{t('rpt_month_custs_product')}</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/reports/month_custs'>
                  <NavDropdown.Item>{t('rpt_month_custs')}</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/reports/month_car'>
                  <NavDropdown.Item>{t('rpt_month_car')}</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/reports/month_cust_car_movs'>
                  <NavDropdown.Item>{t('rpt_month_cust_car_movs')}</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/reports/month_cust_car_movs_print'>
                  <NavDropdown.Item>{t('rpt_month_cust_car_movs_print')}</NavDropdown.Item>
                </LinkContainer>

                <NavDropdown.Divider />

                <LinkContainer to='/reports/yetra_detailed_by_client'>
                  <NavDropdown.Item>{t('yetra_detailed_by_client')}</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/reports/clients_yetra'>
                  <NavDropdown.Item>{t('clients_yetra')}</NavDropdown.Item>
                </LinkContainer>

                <LinkContainer to='/reports/clients_no_payment_after_date'>
                  <NavDropdown.Item>{t('clients_no_payment_after_date')}</NavDropdown.Item>
                </LinkContainer>

              </NavDropdown>

            )}



            {userInfo && (
              <NavDropdown title={t('Actions')} id='actionsmenu'>
                <LinkContainer to='/admin/import'>
                  <NavDropdown.Item>{t('Import Csv')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/manual'>
                  <NavDropdown.Item>{t('Manual Add')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/payment'>
                  <NavDropdown.Item>{t('Payment Add')}</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

            {userInfo && (
              <NavDropdown title={t('data_tabels')} id='cardsmenu'>
                <LinkContainer to='/admin/productlist'>
                  <NavDropdown.Item>{t('Product List')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/cardlist'>
                  <NavDropdown.Item>{t('Card List')}</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to='/admin/priceslist'>
                  <NavDropdown.Item>{t('Price List')}</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
            )}

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </header>
}

export default Header
