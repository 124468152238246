export const appName = 'FuelPlus'
export const currencySign = '₪'
export const appLogo = 'fuelplus.png'
export const variant_main = 'warning'
export let discount_kind = 1;

export const getDiscountKind = () => {
  return discount_kind;
};

export const setDiscountKind = (value) => {
  discount_kind = value;
};

export var axoisConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }