import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { registerLicense } from '@syncfusion/ej2-base';
import { GridComponent, ForeignKey, ColumnsDirective, ColumnDirective, Inject, Edit, Toolbar, Page, Sort, Filter, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DataManager, ODataAdaptor, Query, WebApiAdaptor, ReturnOption } from '@syncfusion/ej2-data';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { axoisConfig, variant_main } from '../constants/generalConstants'
import { Button } from 'react-bootstrap'

import { useTranslation } from 'react-i18next';
import e from 'cors';

registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');

const PaymentScreen = ({ history }) => {
  const [data, setData] = useState([]);
  // const [cards, setCards] = useState([]);
  // const [products, setProducts] = useState([]);
  const gridRef = useRef(null);

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  //
  const [dataCards, setDataCards] = useState([]);
  const [dataPayType, setDataPayType] = useState([]);
  const [selectedClient, setSelectedClient] = useState(0);
  // const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  
 
  const oneMonthLater = new Date();
  oneMonthLater.setMonth(oneMonthLater.getMonth() - 1);
  oneMonthLater.setDate(1);

  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [selectedDate1, setSelectedDate1] = useState(oneMonthLater);

  const formattedDate = (d) => {
    const cd = num => num.toString().padStart(2, 0);
    return d.getFullYear() + "/" + cd(d.getMonth() + 1) + "/" + cd(d.getDate());
  }

  let previousDate = formattedDate(new Date());

  const loadData = async () => {
    try {

      const data = {
        // JSON data to be sent in the request body
        dt1: selectedDate1,
        dt2: selectedDate2,
        cardnum1: selectedClient
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const Response = await axios.post('/api/fuel/last_payment_movs', data, config);
      setData(Response.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const loadDataCards = async () => {
    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/fuel/clients', config);
      let data1 = response.data
      const newElement = { card_num: 0, card_name: `${t('ALL')}` };
      data1.unshift(newElement);
      //data1.push(newElement);

      setDataCards(data1);

      // .then(response => 
      //   response.json()
      // )
      //   .then(result => {
      //     return { "result": result, "count": result.length };
      //   })

    } catch (error) {
      console.log(error);
    }
  };

  const loadDataPaymentType = async () => {
    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/fuel/payment_types', config);
      const data1 = response.data;
      setDataPayType(data1);

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    loadDataCards();
   loadDataPaymentType();    
   loadData();

    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

    var buttonWithAdd = findButtonWithAdd(); // document.querySelector('input[innerText="Add"]');
    if (buttonWithAdd) {
      document.addEventListener("keydown", function (event) {

        if (event.key === "+") {
          // Simulate a click on the button
          event.preventDefault();
          buttonWithAdd.click();
          var input = document.querySelector('input[name="rsm_date"]');

          if (input) {
            input.value = previousDate;
            var inputName = document.querySelector('input[name="rsm_card_name"]');
            // TBD: inputName focus
            inputName?.focus();
          }

        }
      });
    }

  }, [history]);


  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  function findButtonWithAdd() {
    var buttons = document.querySelectorAll('button');

    // Loop through the buttons to find the one with a span containing "Add"
    var buttonWithAdd = null;
    for (var i = 0; i < buttons.length; i++) {
      var button = buttons[i];

      if (button.innerText === "Add") {
        buttonWithAdd = button;
        break; // Stop searching once found
      }
    }
    return buttonWithAdd;
  }

  const toolbarOptions = ['Add', 'Edit', 'Update', 'Delete', 'Cancel'];

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal' //, showConfirmDialog: false
  }


  const handleBeforeBatchAdd = (args) => {
    var input = document.querySelector('input[name="rsm_date"]');
    if (input) {
      previousDate = input.value;
    }
  }

  const handleActionComplete = (args) => {
    let config = { ...axoisConfig }
    config.headers.Authorization = `${userInfo.token}`;

    if (args.requestType === 'save' && args.action === 'add') {

      let dt1=new Date(args.data.rsm_date);
      dt1.setHours(dt1.getHours() + 10);
      args.data.rsm_date=dt1;    

      //Insert New Records     
      axios.post('/api/fuel/AddPayment', args.data, config)
        .then((response) => {
          console.log(data);
          let tmpdata = data;
          //tmpdata.splice(0, 1, response.data[0]);
          tmpdata[0].rsm_sn = response.data[0].rsm_sn;
          setData(tmpdata);
        })
        .catch((error) => {
          console.error('Error adding data:', error);
        });
      //alert('Add New Record');
    } else if (args.requestType === 'save' && args.action === 'edit') {
      
      let dt1=new Date(args.data.rsm_date);
      dt1.setHours(dt1.getHours() + 10);
      args.data.rsm_date=dt1;    
      
      // Save the new records to the server here if needed
      axios.put(`/api/fuel/AddPayment/${args.data.rsm_sn}`, args.data, config)
        .then((response) => {
          console.log('Update Record Success');
          console.log(response.data[0]);

        })
        .catch((error) => {
          console.error('Error adding data:', error);
        });
    } else if (args.requestType === 'delete') {
      alert('delete');
      axios.delete(`/api/fuel/AddPayment/${args.data[0].rsm_sn}`, config)
        .catch((error) => {
          console.error('Error deleting data:', error);
        });
    }


  };


  const createCustomerIDFn = () => {
    inpuEle = document.createElement('input');
    return inpuEle;
  };
  const destroyCustomerIDFn = () => {
    autoCompleteIns.destroy();
  };
  const readCustomerIDFn = () => {
    return autoCompleteIns.value;
  };
  const writeCustomerIDFn = async (args) => {

    try {
      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const cardsResponse = await axios.get('/api/cards', config);

      autoCompleteIns = new AutoComplete({
        allowCustom: false,
        value: args.rowData[args.column.field],
        dataSource: cardsResponse.data,
        fields: { value: 'card_name', text: 'card_name' },
      });
      autoCompleteIns.appendTo(args.element);
    } catch (error) {
      console.error("Error fetching cards data:", error);
    }
  };


  // const createPaymentTypeFn = () => {
  //   inpuEleProduct = document.createElement('input');
  //   return inpuEleProduct;
  // };
  // const destroyPaymentTypeFn = () => {
  //   autoCompleteInsProduct.destroy();
  // };
  // const readPaymentTypeFn = () => {
  //   return autoCompleteInsProduct.value;
  // };
  // const writePaymentTypeFn = async (args) => {

  //   try {

  //     let config = { ...axoisConfig }
  //     config.headers.Authorization = `${userInfo.token}`;

  //     const paymentTypeResponse = await axios.get('/api/fuel/payment_types', config);

  //     // autoCompleteInsProduct = new AutoComplete({
  //     //   allowCustom: false,
  //     //   value: args.rowData[args.column.field],
  //     //   dataSource: paymentTypeResponse.data,
  //     //   fields: { value: 'pyt_key', text: 'pyt_value' },
  //     // });
  //     autoCompleteInsProduct = new DropDownList({
  //       dataSource:  paymentTypeResponse.data,
  //       enabled: true,
  //       value: args.rowData[args.column.field],
  //       fields: { value: 'pyt_key', text: 'pyt_value' },
  //       floatLabelType: 'Never',
  //       placeholder: 'Select a payType'
  //   });
  //     autoCompleteInsProduct.appendTo(args.element);
  //   } catch (error) {
  //     console.error("Error fetching Products data:", error);
  //   }
  // };

  let inpuEle;
  let autoCompleteIns;

  let inpuEleProduct;
  let autoCompleteInsProduct;

  const daParams = {
    create: createCustomerIDFn,
    destroy: destroyCustomerIDFn,
    read: readCustomerIDFn,
    write: writeCustomerIDFn
  };

  // const daPrParams = {   
  //   create: createPaymentTypeFn,
  //   destroy: destroyPaymentTypeFn,
  //   read: readPaymentTypeFn,
  //   write: writePaymentTypeFn
  // };
  const { t } = useTranslation();
  const NumericRequiredValidator = { required: true, number: true };
  const NumericValidator = { number: true };
  const RequiredValidator = { required: true };

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    //console.log(`Year=${selectedYear} Month=${selectedMonth + 1}`);
    console.log(`Date1=${selectedDate1} Date2=${selectedDate2}`);
    console.log(`selectedClients=${selectedClient}`);
    // refreshGrid(userInfo);
    loadData();
  }

  
  const handleDate1Change = (args) => {
    setSelectedDate1(args.value);    
  };

  const handleDate2Change = (args) => {
    setSelectedDate2(args.value);    
  };

  const handleClientValueChange = (args) => {
    const newSelectedItems = args.value; // Array of selected items
    setSelectedClient(newSelectedItems);
  };


  const inputStyle = {
    border: 'none',
    outline: 'none', // Remove the blue outline on focus
    borderBottom: '1px solid #ccc', // Add a bottom border for a subtle separator
    width: '100%',
    padding: '5px', // Adjust padding as needed 
    textAlign: 'right'
  };

  function footerSum(props) {
    return (<span>{t('Sum')}: {props.Sum}</span>);
  }

  const dataPay = [
    {
      "pyt_key": 9,
      "pyt_value": "העברה בנקאית"
    },
    {
      "pyt_key": 4,
      "pyt_value": "כרטיס אשראי"
    },
    {
      "pyt_key": 3,
      "pyt_value": "יתרת פתיחה"
    },
    {
      "pyt_key": 2,
      "pyt_value": "מזומן"
    },
    {
      "pyt_key": 1,
      "pyt_value": "שיק"
    }
  ];

  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Payment Add')}</h1>
      <div className="cretria grid4container pt-2 pb-5 ps-2">

        <DropDownListComponent id="atcelement"
          enableRtl
          placeholder={t('Select Client')}
          dataSource={dataCards}
          fields={{ value: "card_num", text: "card_name" }}
          change={handleClientValueChange}
        ></DropDownListComponent>

<DatePickerComponent format="dd/MM/yyyy" 
            enableRtl
            value={selectedDate1}
            change={handleDate1Change}
          ></DatePickerComponent>
          <DatePickerComponent  format="dd/MM/yyyy" 
            enableRtl
            value={selectedDate2}
            change={handleDate2Change}
          ></DatePickerComponent>
                
        <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
          {t('Search')}
        </Button>
      </div>

      <GridComponent height={200} ref={gridRef} enableRtl allowPaging={true}
        allowAdding={true}
        allowEditing={true}
        allowFiltering={true}
        allowSorting={true}
        toolbar={toolbarOptions}
        editSettings={editOptions}
        dataSource={data}
        pageSettings={{ pageSize: 25 }}
        beforeBatchAdd={handleBeforeBatchAdd}
        actionComplete={handleActionComplete}>

        <ColumnsDirective>
          <ColumnDirective field='rsm_sn' headerText={t('rsm_sn')} isPrimaryKey={true} visible={false} width='10'></ColumnDirective>
          <ColumnDirective field="rsm_date" headerText={t('issue_date')} width="120" textAlign="Right" type="date" format="dd/MM/yyy" editType="datepickeredit" />
          <ColumnDirective field="rsm_card_name" headerText={t('rsm_card_name')} type="string" width="180" textAlign="Right" edit={daParams} validationRules={RequiredValidator} />
          <ColumnDirective field="rsm_pay_type" foreignKeyValue='pyt_value' foreignKeyField='pyt_key' dataSource={dataPay} headerText={t('rsm_pay_type')} width="180" textAlign="Right" editType='dropdownedit' validationRules={RequiredValidator} />
          <ColumnDirective field="rsm_total" headerText={t('pay_total')} width="100" textAlign="Right" format="C2" validationRules={NumericRequiredValidator} />
          <ColumnDirective field="rsm_pay_date" headerText={t('rsm_pay_date')} width="120" textAlign="Right" type="date" format="dd/MM/yyy" editType="datepickeredit" />
          
          <ColumnDirective field="rsm_bank" headerText={t('rsm_bank')} width="60" textAlign="Right" validationRules={NumericValidator} />
          <ColumnDirective field="rsm_branch" headerText={t('rsm_branch')} width="60" textAlign="Right" validationRules={NumericValidator} />
          <ColumnDirective field="rsm_account" headerText={t('rsm_account')} width="100" textAlign="Right" validationRules={NumericValidator} />
          <ColumnDirective field="rsm_pay_num" headerText={t('rsm_pay_num')} width="100" textAlign="Right" validationRules={NumericValidator} />

          <ColumnDirective field="rsm_src" headerText={t('rsm_src')} width="1" defaultValue='payment' textAlign="Right" type='string' />
        </ColumnsDirective>
        <AggregatesDirective>
          <AggregateDirective>
            <AggregateColumnsDirective>
              <AggregateColumnDirective field='rsm_qnt' type='Sum' format='N2' footerTemplate={footerSum} > </AggregateColumnDirective>
              <AggregateColumnDirective field='rsm_total' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
            </AggregateColumnsDirective>
          </AggregateDirective>

        </AggregatesDirective>
        <Inject services={[Edit, Toolbar, Page, Aggregate, Sort, Filter, ForeignKey]} />
      </GridComponent>

    </div>
  );
};

export default PaymentScreen;
