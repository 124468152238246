import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import Message from '../components/Messages'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'

import { useTranslation } from 'react-i18next';
import { variant_main } from '../constants/generalConstants'


const LoginScreen = ({ location, history }) => {
  const [mobile, setMobile] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])


  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(mobile, password))
  }
  const { t } = useTranslation();

  return (
    <div className="viewcontainer">
      <FormContainer>
        <h1 className="display-5 fw-bold mb-3 text-capitalize main-color stroke">{t('Sign In')}</h1>
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='usr_mobile'>
            <Form.Label>{t('Mobile Address')}</Form.Label>
            <Form.Control
              type='number'
              placeholder={t('Enter mobile')}
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='usr_password'>
            <Form.Label>{t('Password')}</Form.Label>
            <Form.Control
              type='password'
              placeholder={t('Enter password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button className="my-3" type='submit' variant={variant_main} >
            {t('Sign In')}
          </Button>
        </Form>

        {/* <Row className='py-3'>
                <Col>
                    {t('New Customer')}?{' '}
                    <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                        {t('Register')}
                    </Link>
                </Col>
            </Row> */}
      </FormContainer>
    </div>
  )
}

export default LoginScreen
