import { useEffect, useState } from 'react';

import { GridComponent, Inject, toolbarClick } from '@syncfusion/ej2-react-grids';
import { ColumnsDirective, ColumnDirective, CommandColumn, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import { Page, Filter, Sort, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import iconv from 'iconv-lite';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { axoisConfig, variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'

import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

//import { getMonthlyCustsSum_ByProduct } from '../services/ordersService';
import axios from 'axios';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXlcc3RTRGNYUEJwWUA=');
const MonthCustsProductScreen = ({ history }) => {

  const [data, setData] = useState([]);
  //const [selectedDate, setSelectedDate] = useState(null);

  const [dataCards, setDataCards] = useState([]);
  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedSrc, setSelectedSrc] = useState('');
  //
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  // const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() == 0 ? 12 : new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(selectedMonth == 12 ? new Date().getFullYear() - 1 : new Date().getFullYear());


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {
      loadDataCards();
      loadData();
    } else {
      history.push('/login')
    }
    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

  }, [history]);


  const loadDataCards = async () => {
    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/fuel/clients', config);
      let data1 = response.data
      const newElement = { card_num: 0, card_name: `${t('ALL')}` };
      data1.unshift(newElement);
      //data1.push(newElement);

      setDataCards(data1);

      // .then(response => 
      //   response.json()
      // )
      //   .then(result => {
      //     return { "result": result, "count": result.length };
      //   })

    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async () => {
    try {
      const data = {
        // JSON data to be sent in the request body
        m1: selectedMonth,
        y1: selectedYear,
        cardnum1: selectedClient,
        src1: selectedSrc
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.post('/api/fuel/rpt_month_custs_product', data, config);

      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const UpdPricesData = async () => {
    try {
      const data = {
        // JSON data to be sent in the request body
        m1: selectedMonth,
        y1: selectedYear
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.post('/api/fuel/upd_prices_manual_movs', data, config);
      console.log(response.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0).getDate();
  }

  // Function to convert JSON to tab-delimited string
  function jsonToTabDelimited(jsonData) {
    // Headers for the tab-delimited file (assuming all objects have the same keys)
    //const headers = Object.keys(jsonData[0]);
    let price1 = 0;
    let hanaha = 0;
    let hanaha2 = 0;
    let str = '';
    const lastDay = getLastDayOfMonth(selectedYear, selectedMonth-1);
    jsonData.forEach((row, i) => {
      if (row.card_export) {
        if (row.sum_qnt > 0) {
          price1 = Number(row.sum_price_final / 1.17).toFixed(2);

          if (Number(row.card_discount) > 0) {
            hanaha = Number(row.card_discount) / 100;
            //price1 = price1 * (1 - hanaha);
          } else hanaha = 0;
          if (Number(row.card_discount2) > 0) {
            hanaha2 = Number(row.card_discount2) / 100;
            //price1 = price1 * (1 - hanaha);
          } else hanaha2 = 0;
                 
          str += '1'; //hen_kabala_type
          str += '\t' + `${lastDay}-${selectedMonth}-${selectedYear}`; //trnsfr_date
          str += '\t' + `${lastDay}-${selectedMonth}-${selectedYear}`; //issu_date
          str += '\t' + `1`; //hen_type
          str += '\t' + row.rsm_product_sn; //item_part_num
          str += '\t' + row.rsm_product; //item_name      
          if (row.rsm_discount_kind == 1) { //check if discount  in percent 1 or agorot 2
             if (row.rsm_product_sn == 2 && hanaha > 0) str += ' - הנחה : ' + row.card_discount + '%'; //hanaha      
             if (row.rsm_product_sn == 1 && hanaha2 > 0) str += ' - הנחה : ' + row.card_discount2 + '%'; //hanaha    
          } else {
            if (row.rsm_product_sn == 2 && hanaha > 0) str += ' - הנחה : ' +  row.card_discount + 'אג.'; //(hanaha * row.sum_qnt) + 'שח'; //hanaha      
            if (row.rsm_product_sn == 1 && hanaha2 > 0) str += ' - הנחה : ' + row.card_discount2 + 'אג.'; //(hanaha2 * row.sum_qnt) + 'שח'; //hanaha    
          }  
          str += '\t' + row.sum_qnt; //item_qtt
          if (row.sum_qnt > 0) {
            //if (row.rsm_product_sn == 2) str += '\t' + (Number(price1 / row.sum_qnt)*(1 - hanaha)).toFixed(4); //item_price
            str += '\t' + (Number(price1 / row.sum_qnt)).toFixed(4); //item_price
          }
          else str += '\t' + Number(0).toFixed(1); //item_price
          str += '\t' + Number(price1).toFixed(2); //ttl_line
          str += '\t' + row.rsm_card_num; //cust_id
          str += '\t' + row.rsm_card_name; //cust_lname
          str += '\t' + ''; //cust_fname
          str += '\t' + ''; //street
          str += '\t' + '';//city
          str += '\t' + row.rsm_card_num; //inernal_num
          str += '\t' + `${lastDay}-${selectedMonth}-${selectedYear}`; //due_date
          str += '\t' + '3' + '\t' + '' + '\t' + '' + '\t' + ''; //pmnt_type + check_num + bank_code + snif_num
          str += '\t' + '' + '\t' + '1' + '\t' + '' + '\t' + '' + '\t' + '100'; //hen_bank_num + mtc + mtc_rate + mtc_item_price + sort_code     
          str += '\t' + ''; //internal_order
          str += '\t' + '' + '\t' + ''; //price_bruto + id_number
          str += '\n';
        }
      }
    });

    // Creating the tab-delimited content
    // const content = jsonData.map(obj => headers.map(header => obj[header]).join('\t'));

    // Adding headers as the first row
    //content.unshift(headers.join('\t'));

    return str;//content.join('\n');
  }

  function getTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}`;
  }

  const dataSrcs = [
    { name: " הכל", value: "" },
    { name: "רוזמן", value: "rsm" },
    { name: "פנקסים", value: "manual" },
  ];
  // Function to convert a string to ANSI encoding (windows-1255)
  const stringToANSI = (str) => {
    const buffer = iconv.encode(str, 'windows-1255');
    return new Uint8Array(buffer);
  };

  const ExportRivhitData = () => {
    const tabDelimitedData = jsonToTabDelimited(data);
    // Convert tabDelimitedData to ANSI encoded Uint8Array
    const ansiEncodedData = stringToANSI(tabDelimitedData)

    const blob = new Blob([ansiEncodedData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `Rivhit-${selectedYear}-${selectedMonth}_${getTimestamp()}.txt`;
    a.click();
    URL.revokeObjectURL(url);
  };

  function footerSum(props) {
    return (<span>{t('Sum')}: {props.Sum}</span>);
  }

  let gridInstance;
  let flag = true;

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true
  }
  const toolbarOptions = ['ExcelExport']

  const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
  { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
  { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];


  let mygrid = null;

  const selectionOptions = {
    checkboxMode: 'ResetOnRowClick',
    enableToggle: true
  }


  const { t } = useTranslation();

  const exportProperties = {
    fileName: 'invoice.pdf',
    exportType: 'CurrentPage'
  };
  const toolbarClick = (args) => {
    if (gridInstance && args.item.id.includes('pdfexport')) {
      gridInstance.pdfExport(exportProperties);
    } else if (gridInstance && args.item.id.includes('excelexport')) {
      gridInstance.excelExport();
    }
  }

  //const products = ['סולר', 'בנזין 95', 'אוריה', 'שתיה', 'סגריות'];

  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    console.log(`Year=${selectedYear} Month=${selectedMonth}`);
    //console.log(`selectedProducts=${selectedProducts}`);
    console.log(`selectedClient=${selectedClient}`);
    console.log(`selectedSrc=${selectedSrc}`);
    // refreshGrid(userInfo);
    loadData();
  }

  const handleUpdPricesClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))
    console.log(`Year=${selectedYear} Month=${selectedMonth}`);
    UpdPricesData();
    loadData();
  }
  //
  const handleRivhitClick = (e) => {
    e.preventDefault()
    ExportRivhitData();
  }

  const handleDateChange = (args) => {
    setSelectedYear(args.value?.getFullYear());
    setSelectedMonth(args.value?.getMonth() + 1);
  };

  const handleClientValueChange = (args) => {
    const newSelectedItems = args.value; // Array of selected items
    setSelectedClient(newSelectedItems);
  };
  //
  const handleSrcValueChange = (args) => {
    const newSelectedItems = args.value; // Array of selected items
    setSelectedSrc(newSelectedItems);
  };

  const filterOptions = {
    ignoreAccent: true,
    type: 'Excel'
};

  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Rpt Month Custs Product')}</h1>
      <div className="cretria grid3container pt-2 pb-5 ps-2">

        <div className="grid2container">
          <DatePickerComponent start="Year" format="MM/yyyy" depth="Year"
            enableRtl
            value={new Date(selectedYear, selectedMonth - 1, 1)}
            change={handleDateChange}
          ></DatePickerComponent>
          <DropDownListComponent id="Srcatcelement"
            enableRtl
            placeholder={t('Select Src')}
            dataSource={dataSrcs}
            value={selectedSrc}
            fields={{ value: "value", text: "name" }}
            change={handleSrcValueChange}
          ></DropDownListComponent>
        </div>
        <div className="grid2container">
          <DropDownListComponent id="atcelement"
            enableRtl
            placeholder={t('Select Client')}
            dataSource={dataCards}
            value={selectedClient}
            fields={{ value: "card_num", text: "card_name" }}
            change={handleClientValueChange}
          ></DropDownListComponent>

        </div>


        <div className="grid3container">
          <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
            {t('Search')}
          </Button>
          <Button className="m-1" onClick={handleRivhitClick} variant={variant_main} >
            {t('Export To Rivhit')}
          </Button>
          <Button className="m-1" onClick={handleUpdPricesClick} variant={variant_main} >
            {t('Update Prices')}
          </Button>
        </div>
      </div>
      <GridComponent className="mx-0" enableRtl ref={grid => gridInstance = grid} id="mygrid" name="mygrid" dataSource={data}
        allowPaging={true}
        pageSettings={{ pageSize: 10, pageSizes: true, pageCount: 4 }}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick.bind(this)}
        allowSorting={true}
        allowFiltering={true}
        allowExcelExport={true}
        filterSettings={filterOptions}
      /// dataStateChange={dataStateChange.bind(this)}
      // frozenRows={0} frozenColumns={1}
      //dataSourceChanged={dataSourceChanged}
      >
        <ColumnsDirective>
          {/* <ColumnDirective field='gender' headerText='Gender' textAlign='Right' width='100' />
          <ColumnDirective field='email' headerText='Email' width='150' />
          <ColumnDirective field='nat' headerText='Nationality' /> */}
          <ColumnDirective field='rsm_card_num' headerText={t('rsm_card_num')} textAlign='Right' width='100' isPrimaryKey={true} />
          <ColumnDirective field='rsm_card_name' headerText={t('rsm_card_name')} textAlign='Right' width='150' />
          <ColumnDirective field='rsm_product' headerText={t('rsm_product')} width='150' />
          <ColumnDirective field='sum_qnt' headerText={t('sum_qnt')} width='80' />
          <ColumnDirective field='sum_price' headerText={t('sum_price_Before')} width='80' />
          <ColumnDirective field='sum_price_final' headerText={t('sum_price_final')} width='80' />
          <ColumnDirective field='card_export' format="boolean" headerText={t('card_export')} displayAsCheckBox={true} width='50' />
        </ColumnsDirective>
        <AggregatesDirective>
          <AggregateDirective>
            <AggregateColumnsDirective>
              <AggregateColumnDirective field='sum_price' type='Sum' format='N2' footerTemplate={footerSum} > </AggregateColumnDirective>
              <AggregateColumnDirective field='sum_qnt' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
              <AggregateColumnDirective
                field="sum_price_final"
                type="Sum" // Specify the custom aggregate type         
                format="N2" // Format the aggregate value
                footerTemplate={footerSum}
              />
            </AggregateColumnsDirective>
          </AggregateDirective>

        </AggregatesDirective>
        <Inject services={[Page, Sort, Filter, Toolbar, ExcelExport, Aggregate]} />

      </GridComponent>
    </div>

  )
}

export default MonthCustsProductScreen
