import { useEffect, useRef, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { FileUpload } from '@syncfusion/ej2-react-grids';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { variant_main } from '../constants/generalConstants';
import FormContainer from '../components/FormContainer';
//import ToastComponent from '../components/ToastComponent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faTrash, faRemove, faUpload } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { fileService } from '../services/fileService';
import copy from 'clipboard-copy';
import '../App.css';
//import data from './datasource.json';
import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';


//registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');

const CsvUploadScreen = ({ history }) => {
  // state definition
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState('');
  const [toastColor, setToastColor] = useState('');

  const [show, setShow] = useState(false);
  const [modalText, setModalText] = useState('');
  
  //const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);

  const { t } = useTranslation();

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {

    } else {
      history.push('/login')
    }
    // // Example usage
    // const element = findElementWithText("To remove the license validation");
    // if (element) {
    //   console.log("Element found:", element);
    //   element.style.display = 'none';
    //   element.parentNode.removeChild(element);
    // } else {
    //   console.log("Element not found.");
    // }
  }, [history]);



  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
    setPreviewFiles([]);

    const previews = [];
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (ev) => {
        previews.push({ file, preview: ev.target.result });
        if (previews.length === files.length) {
          setPreviewFiles(previews);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileDrop = (e) => {
    console.log("File(s) dropped");
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles(files);
    setPreviewFiles([]);

    const previews = [];
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (ev) => {
        previews.push({ file, preview: ev.target.result });
        if (previews.length === files.length) {
          setPreviewFiles(previews);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleShowToast = (text, color) => {
    setToastText(text);
    setToastColor(color);
    setShowToast(true);
  };

  // const handleCloseToast = () => {
  //   setShowToast(false);
  // };

  const handleCloseModal = async () => {
    try {
      setShow(false);
      await copy(modalText);      
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };


  const submitInputFile = (e) => {
    console.log("File(s) in drop zone");
    e.preventDefault();

    const selectedFile = selectedFiles[0]
    fileService.submitInputFile(selectedFile).then(response => response.json())
      .then(result => {
        console.log('result:', result.result);
        console.log('Count:', result.count);
        handleShowToast('Rsm Records : ' + result.count, 'success');    
        const tt= result.new_cards.map(obj => `${obj.card_num}\t${obj.card_name}`).join('\n'); //.map(obj => Object.values(obj).join('\n')); //.map(obj => JSON.stringify(obj)).join('\n');    
        setModalText(tt);
        handleShowModal();
        //toast.success(this.props.t('response-ok') + ", " + this.props.t('check-uploaded-payments'), { autoClose: false });
      })
      .catch(error => {
        console.error('Error:', error);
        handleShowToast('response-Error : 0', 'danger');
        //toast.error(this.props.t('response-error'), { autoClose: false });
      });

  };

  const handleDragOver = (e) => {
    console.log("File(s) in drop zone");
    e.preventDefault();
  };

  const handleFileRemove = (fileIndex) => {
    const newSelectedFiles = [...selectedFiles];
    const newPreviewFiles = [...previewFiles];

    newSelectedFiles.splice(fileIndex, 1);
    newPreviewFiles.splice(fileIndex, 1);

    setSelectedFiles(newSelectedFiles);
    setPreviewFiles(newPreviewFiles);
  };

  const renderPreview = (file, index) => {
    const { name, type } = file;
    const preview = previewFiles.find((item) => item.file === file)?.preview;

    if (type.includes('image')) {
      return (
        <div key={index} className="preview-item">
          <img src={preview} alt={`Preview ${index}`} className="preview-image" />
          <span className="preview-remove" onClick={() => handleFileRemove(index)}>
            <FontAwesomeIcon icon={faRemove} />
          </span>
        </div>
      );
    }

    return (
      <div key={index} className="preview-item row">
        <div className="file-icon col-3">
          <FontAwesomeIcon icon={faFile} size="4x" className='text-info' />
        </div>
        <div className="file-info col-6">
          <div className="file-name">{name}</div>
        </div>

        <span className="preview-remove col-1" onClick={() => handleFileRemove(index)}>
          <FontAwesomeIcon icon={faTrash} size="2x" className='m-3' />
        </span>
      </div>
    );
  };

  return (
    <div className="viewcontainer">
      <FormContainer>
        <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Import Csv')}</h1>
        <div className="cretria pt-2 pb-5">

          <div className="file-upload-container">
            <label htmlFor="file-upload" className="file-upload-label btn btn-warning">

              <div className="my-1 " variant={variant_main} >
                {t('Choose File')}
              </div>
            </label>

            <input className="file-upload-btn" variant={variant_main}
              id="file-upload"
              type="file"
              // multiple
              onChange={handleFileChange}
              onDrop={handleFileDrop}
              onDragOver={handleDragOver}
              accept=".xls,.xlsx,.csv"
            />
            {previewFiles.length > 0 && (
              <div className="preview-container">
                {previewFiles.map((file, index) => renderPreview(file.file, index))}
              </div>
            )}
            <button onClick={submitInputFile} className="m-3 p-3">
              <FontAwesomeIcon icon={faUpload} className="upload-icon" size="2x" />
            </button>
          </div>
        </div>
      </FormContainer>
      
      <ToastContainer className='toast-style' position="top-center"  >
        <Toast onClose={() => setShowToast(false) } bg={toastColor} show={showToast} delay={5000} autohide>
          <Toast.Header>
            <strong className="me-auto">FuelPlus</strong>
          </Toast.Header>
          <Toast.Body>{toastText}</Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal show={!showToast && show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('New Cards')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
          {t('Copy & Close')}
          </Button>         
        </Modal.Footer>
      </Modal>

      </div>

  );

}

export default CsvUploadScreen
