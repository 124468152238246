import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridComponent, Inject, toolbarClick } from '@syncfusion/ej2-react-grids';
import { ColumnsDirective, ColumnDirective, CommandColumn, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import {
  Page, Filter, Sort, Edit, Freeze, Resize, Reorder, Scroll,
  Toolbar, ExcelExport, PdfExport, Grid
} from '@syncfusion/ej2-react-grids';
import { DropDownListComponent, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { axoisConfig, variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next';

//import { getMonthlyCustsSum_ByProduct } from '../services/ordersService';
import axios from 'axios';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXlcc3RTRGNYUEJwWUA=');
const MonthCarScreen = ({ history }) => {

  const { query } = useParams();

  const [data, setData] = useState([]);
  const [dataCards, setDataCards] = useState([]);

  let qu1=0
  if (query) {
    console.log(query);
    qu1 = Number(query);
  }
  const [selectedClient, setSelectedClient] = useState(qu1);
  //const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  // const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() == 0 ? 12 : new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(selectedMonth == 12 ? new Date().getFullYear() - 1 : new Date().getFullYear());

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin



  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {
      loadDataCards();    
      loadData();
    } else {
      history.push('/login')
    }
    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

  }, [history, query]);

  const loadData = async () => {
    try {
      const data = {
        // JSON data to be sent in the request body
        m1: selectedMonth,
        y1: selectedYear,
        cardnum1: selectedClient
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.post('/api/fuel/rpt_month_car', data, config);

      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  const loadDataCards = async () => {
    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/fuel/clients', config);
      setDataCards(response.data);
      const selectedValue = query || 0; // Use an empty string as the fallback
      const dropdownElement = document.getElementById('atcelement');
      dropdownElement.value = selectedValue;
      dropdownElement.ej2_instances[0].value = Number(selectedValue);
      // .then(response => 
      //   response.json()
      // )
      //   .then(result => {
      //     return { "result": result, "count": result.length };
      //   })

    } catch (error) {
      console.log(error);
    }
  };


  function footerSum(props) {
    return (<span>{t('Sum')}: {props.Sum}</span>);
  }

  let gridInstance;
  let flag = true;

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true
  }
  const toolbarOptions = ['ExcelExport']

  const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
  { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
  { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];


  let mygrid = null;

  const selectionOptions = {
    checkboxMode: 'ResetOnRowClick',
    enableToggle: true
  }



  //function dataSourceChanged(e) {
  // if (e.action === "add") {
  //   addOrder(userInfo, e.data)
  //     .then(res => refreshGrid(userInfo));
  // } else if (e.action === "edit") {
  //   updateOrder(userInfo, e.data)
  //     .then(res => refreshGrid(userInfo));
  // } else if (e.requestType === "delete") {
  //   deleteOrder(userInfo, e.data[0])
  //     .then(res => refreshGrid(userInfo));
  // } else {
  //  refreshGrid(userInfo);
  // }

  //}

  const btnClick = (event) => {
    //event.preventDefault();
    if (mygrid) {
      var selectedRecords = mygrid.getSelectedRecords();
      console.log(selectedRecords);
    }
  }


  //const licenseKey = "ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb"

  // const odata = new DataManager({
  //   //url: 'https://randomuser.me/api/?page=0&results=500&inc=gender,nat,email  ',
  //   url: 'https://services.odata.org/V4/Northwind/Northwind.svc/Orders',
  //   adaptor: new ODataV4Adaptor(),
  //   offline: false
  // });

  //validations
  //const orderidRules = { required: true, number: true };  

  const { t } = useTranslation();

  const exportProperties = {
    fileName: 'invoice.pdf',
    exportType: 'CurrentPage'
  };
  const toolbarClick = (args) => {
    if (gridInstance && args.item.id.includes('pdfexport')) {
      gridInstance.pdfExport(exportProperties);
    } else if (gridInstance && args.item.id.includes('excelexport')) {
      gridInstance.excelExport();
    }
  }

  //const products = ['סולר', 'בנזין 95', 'אוריה', 'שתיה', 'סגריות'];

  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    console.log(`Year=${selectedYear} Month=${selectedMonth}`);
    //console.log(`selectedProducts=${selectedProducts}`);
    console.log(`selectedClients=${selectedClient}`);
    // refreshGrid(userInfo);
    loadData();
  }

  const handleDateChange = (args) => {
    setSelectedYear(args.value?.getFullYear());
    setSelectedMonth(args.value?.getMonth() + 1);
  };

  const handleClientValueChange = (args) => {
    const newSelectedItems = args.value; // Array of selected items
    setSelectedClient(newSelectedItems);
  };

  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Rpt Month Car')}</h1>
      <div className="cretria grid3container pt-2 pb-5 ps-2">



        <DropDownListComponent id="atcelement"
          enableRtl
          placeholder={t('Select Client')}
          dataSource={dataCards}
          value={selectedClient}
          fields={{ value: "card_num", text: "card_name" }}
          change={handleClientValueChange}
        ></DropDownListComponent>

        <DatePickerComponent start="Year" format="MM/yyyy" depth="Year"
          enableRtl
          value={new Date(selectedYear, selectedMonth - 1, 1)}
          change={handleDateChange}
        ></DatePickerComponent>
        <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
          {t('Search')}
        </Button>

      </div>
      <GridComponent className="mx-0" enableRtl ref={grid => gridInstance = grid} id="mygrid" name="mygrid" dataSource={data}
        allowPaging={true}
        pageSettings={{ pageSize: 10, pageSizes: true, pageCount: 4 }}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick.bind(this)}
        allowSorting={true}
        allowExcelExport={true}
      /// dataStateChange={dataStateChange.bind(this)}
      // frozenRows={0} frozenColumns={1}
      //dataSourceChanged={dataSourceChanged}
      >
        <ColumnsDirective>
          {/* <ColumnDirective field='gender' headerText='Gender' textAlign='Right' width='100' />
          <ColumnDirective field='email' headerText='Email' width='150' />
          <ColumnDirective field='nat' headerText='Nationality' /> */}
          <ColumnDirective field='rsm_card_num' headerText={t('rsm_card_num')} textAlign='Right' width='100' isPrimaryKey={true} />
          <ColumnDirective field='rsm_card_name' headerText={t('rsm_card_name')} textAlign='Right' width='150' />
          <ColumnDirective field='rsm_id' headerText={t('rsm_id')} width='80' />
          <ColumnDirective field='rsm_car_name' headerText={t('rsm_car_name')} width='150' />
          <ColumnDirective field='sum_qnt' headerText={t('sum_qnt')} width='80' />
          <ColumnDirective field='sum_price_final' headerText={t('sum_price_final')} width='80' />
        </ColumnsDirective>
        <AggregatesDirective>
          <AggregateDirective>
            <AggregateColumnsDirective>
              <AggregateColumnDirective field='sum_price_final' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
              <AggregateColumnDirective field='sum_qnt' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
            </AggregateColumnsDirective>
          </AggregateDirective>

        </AggregatesDirective>
        <Inject services={[Page, Sort, Toolbar, ExcelExport, Aggregate]} />
      </GridComponent>
    </div>

  )
}

export default MonthCarScreen
