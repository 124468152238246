import { useEffect, useState } from 'react';

import { GridComponent, Inject, toolbarClick } from '@syncfusion/ej2-react-grids';
import { ColumnsDirective, ColumnDirective, CommandColumn, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import { Page, Filter, Sort, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import iconv from 'iconv-lite';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { axoisConfig, variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'

import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

//import { getMonthlyCustsSum_ByProduct } from '../services/ordersService';
import axios from 'axios';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXlcc3RTRGNYUEJwWUA=');
const ClientsYtraScreen = ({ history }) => {

  const [data, setData] = useState([]);
  //const [selectedDate, setSelectedDate] = useState(null);

  // const [dataCards, setDataCards] = useState([]);
  // const [selectedClient, setSelectedClient] = useState(0);
   //
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const sixMonthbefore = new Date();
  sixMonthbefore.setMonth(sixMonthbefore.getMonth() - 6);

  const [selectedDate1, setSelectedDate1] = useState(sixMonthbefore);
  const [selectedExtract, setSelectedExtract] = useState('');
  
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {      
      loadData();
    } else {
      history.push('/login')
    }
    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

  }, [history]);


  
  const loadData = async () => {
    try {
      const data = {
        // JSON data to be sent in the request body
        dt1: selectedDate1,
        dt2: selectedDate2,
        extract: selectedExtract
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.post('/api/fuel/rpt_clients_yetra', data, config);

      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };



  
  function getTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}`;
  }

  const dataSrcs = [
    { name: " הכל", value: "" },
    { name: "רוזמן", value: "rsm" },
    { name: "פנקסים", value: "manual" },
  ];
  // Function to convert a string to ANSI encoding (windows-1255)
  const stringToANSI = (str) => {
    const buffer = iconv.encode(str, 'windows-1255');
    return new Uint8Array(buffer);
  };

  

  function footerSum(props) {
    return (<span>{t('Sum')}: {props.Sum}</span>);
  }

  let gridInstance;
  let flag = true;

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true
  }
  const toolbarOptions = ['ExcelExport']

  const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
  { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
  { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];


  let mygrid = null;

  const selectionOptions = {
    checkboxMode: 'ResetOnRowClick',
    enableToggle: true
  }


  const { t } = useTranslation();

  const exportProperties = {
    fileName: 'invoice.pdf',
    exportType: 'CurrentPage'
  };
  const toolbarClick = (args) => {
    if (gridInstance && args.item.id.includes('pdfexport')) {
      gridInstance.pdfExport(exportProperties);
    } else if (gridInstance && args.item.id.includes('excelexport')) {
      gridInstance.excelExport();
    }
  }


  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    console.log(`Date1=${selectedDate1} Date2=${selectedDate2}`);
    //console.log(`selectedProducts=${selectedProducts}`);
    //console.log(`selectedClient=${selectedClient}`);    
    // refreshGrid(userInfo);
    loadData();
  }


  const handleDate1Change = (args) => {
    setSelectedDate1(args.value);    
  };

  const handleDate2Change = (args) => {
    setSelectedDate2(args.value);    
  };

 
  function periodTemp(props) {
    return (<span>{t(props.descr1)}</span>);
}

const excelQueryCellInfo = (args) => {
    if (args.column.field === 'descr1') {
        args.value =  t(args.data.descr1) ;
    }
   
};

// const handleBlur = () => {
//   // Perform any validation or processing here before updating the state
//   const numericValue = (selectedExtract); // Convert the value to a numeric format
//    setSelectedExtract(numericValue); // Round the numeric value to 2 decimal places
  
// };

const handleBlur = () => {
  // let inputValue = event.target.value;

  
  // if (inputValue.endsWith(',')) {
  //   setSelectedExtract(inputValue.slice(0, -1)); // Remove the trailing comma
  // }

  setSelectedExtract((prevInputValue) => prevInputValue.replace(/,+$/, ''));
};


const handleChange = (event) => {
  // Allow only numeric values and a dot (for decimals)   
    let userInput = event.target.value;

    // Ensure there's more than one character, and it doesn't end with a comma
    userInput = userInput.replace(/[^0-9,]/g, '');
    setSelectedExtract(userInput);
 
};


const inputStyle = {
  border: 'none',
  outline: 'none', // Remove the blue outline on focus
  borderBottom: '1px solid #ccc', // Add a bottom border for a subtle separator
  width: '100%',
  padding: '5px', // Adjust padding as needed 
  textAlign: 'left',
  direction: 'ltr'
};

  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Rpt Clients Ytra')}</h1>
      <div className="cretria grid2container pt-2 pb-5 ps-2">

        <div className="grid2container">
          <DatePickerComponent format="dd/MM/yyyy" 
            enableRtl
            value={selectedDate1}
            change={handleDate1Change}
          ></DatePickerComponent>
          <DatePickerComponent  format="dd/MM/yyyy" 
            enableRtl
            value={selectedDate2}
            change={handleDate2Change}
          ></DatePickerComponent>
          
        </div>
       

        <div className="grid2container">
        <input type='text' name='extract' onBlur={handleBlur} onChange={handleChange}
          placeholder={t('Enter a Extract')} style={inputStyle} value={selectedExtract} ></input>

          <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
            {t('Search')}
          </Button>          
        </div>
      </div>
      <GridComponent className="mx-0" enableRtl ref={grid => gridInstance = grid} id="mygrid" name="mygrid" dataSource={data}
        allowPaging={true}
        pageSettings={{ pageSize: 25, pageSizes: true, pageCount: 4 }}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick.bind(this)}
        excelQueryCellInfo={excelQueryCellInfo}
        allowSorting={true}
        allowExcelExport={true}
      /// dataStateChange={dataStateChange.bind(this)}
      // frozenRows={0} frozenColumns={1}
      //dataSourceChanged={dataSourceChanged}
      >
        <ColumnsDirective>
          {/* <ColumnDirective field='gender' headerText='Gender' textAlign='Right' width='100' />
          <ColumnDirective field='email' headerText='Email' width='150' />
          <ColumnDirective field='nat' headerText='Nationality' /> */}
          <ColumnDirective field='card_num' headerText={t('card_num')} textAlign='Right' width='80' isPrimaryKey={true} />
          <ColumnDirective field='card_name' headerText={t('card_name')} textAlign='Right' width='200' />
          <ColumnDirective field='yetra_before' headerText={t('yetra_before')} width='80' />
          <ColumnDirective field='period_hova' headerText={t('period_hova')}  width='100' />
          <ColumnDirective field='period_zchot' headerText={t('period_zchot')} textAlign='right' enableRtl='false' width='80' />          
          <ColumnDirective field='yetra_end_period' headerText={t('yetra_end_period')} width='80' />
          <ColumnDirective field='yetra_now' headerText={t('yetra_now')} width='80' />         
        </ColumnsDirective>
        <AggregatesDirective>
            <AggregateDirective>
              <AggregateColumnsDirective>
                <AggregateColumnDirective field='yetra_now' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>               
              </AggregateColumnsDirective>
            </AggregateDirective>
                          
          </AggregatesDirective>
        <Inject services={[Page, Sort, Toolbar, ExcelExport, Aggregate]} />

      </GridComponent>
    </div>

  )
}

export default ClientsYtraScreen
