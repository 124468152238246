import { useEffect, useRef, useState } from 'react';

import { GridComponent, Inject, toolbarClick } from '@syncfusion/ej2-react-grids';
import { ColumnsDirective, ColumnDirective, CommandColumn } from '@syncfusion/ej2-react-grids';
import {
  Page, Filter, Sort, Edit, Freeze, Resize, Reorder, Scroll,
  Toolbar, ExcelExport, PdfExport, Grid
} from '@syncfusion/ej2-react-grids';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next';

import { addOrder, getOrders, updateOrder, deleteOrder } from '../services/ordersService';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXlcc3RTRGNYUEJwWUA=');
const UserListScreen = ({ history }) => {

  const [data, setData] = useState(null);
  //const [selectedUsers, setSelectedUsers] = useState([]);
  //const [selectedProduct, setSelectedProducts] = useState([]);
  //const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  //const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()+1);

  let selectedClients = [];
  let selectedProducts = [];
  let selectedYear = new Date().getFullYear();
  let selectedMonth = new Date().getMonth()+1;

  const setSelectedClients = (v => selectedClients = v);
  const setSelectedProducts = (v => selectedProducts = v);
  const setSelectedYear = (v => selectedYear = v);
  const setSelectedMonth = (v => selectedMonth = v);

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {
      refreshGrid(userInfo);
    } else {
      history.push('/login')
    }
    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

  }, [history]);

  function refreshGrid(userInfo) {
    getOrders(userInfo)
      .then(
        data => {
          setData(data);
        }
      );
  }

//   function dataStateChange(e) {
//     execute(e).then((gridData) => { mygrid.dataSource = gridData; });
// }

function execute(e) {
    return getOrders(userInfo);
}

  let gridInstance;
  let flag = true;

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true
  }
  const toolbarOptions = ['Edit', 'Update', 'Cancel', 'ExcelExport']

  const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
  { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
  { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];


  let mygrid = null;

  const selectionOptions = {
    checkboxMode: 'ResetOnRowClick',
    enableToggle: true
  }

  function dataBound() {
    // if (flag) {
    //     gridInstance.toolbarModule.toolbar.hideItem(2, true);
    //     flag = false;
    // }
  }

  function dataSourceChanged(e) {
    if (e.action === "add") {
      addOrder(userInfo, e.data)
        .then(res => refreshGrid(userInfo));
    } else if (e.action === "edit") {
      updateOrder(userInfo, e.data)
        .then(res => refreshGrid(userInfo));
    } else if (e.requestType === "delete") {
      deleteOrder(userInfo, e.data[0])
        .then(res => refreshGrid(userInfo));
    }
  }

  const btnClick = (event) => {
    //event.preventDefault();
    if (mygrid) {
      var selectedRecords = mygrid.getSelectedRecords();
      console.log(selectedRecords);
    }
  }

  const rowSelectedEvent = (event) => {
    //event.preventDefault();
    if (mygrid) {
      var selectedRecords = mygrid.getSelectedRecords();
      console.log(selectedRecords);
    }
  }

  function failure(args) {   //Share whatever you get in this args. 
    debugger;
  }

  //const licenseKey = "ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb"

  // const odata = new DataManager({
  //   //url: 'https://randomuser.me/api/?page=0&results=500&inc=gender,nat,email  ',
  //   url: 'https://services.odata.org/V4/Northwind/Northwind.svc/Orders',
  //   adaptor: new ODataV4Adaptor(),
  //   offline: false
  // });

  //validations
  //const orderidRules = { required: true, number: true };  
  const guidRules = { required: true };

  const orderidRules = { required: true };

  const { t } = useTranslation();

  const exportProperties = {
    fileName: 'invoice.pdf',
    exportType: 'CurrentPage'
  };
  const toolbarClick = (args) => {
    if (gridInstance && args.item.id.includes('pdfexport')) {
      gridInstance.pdfExport(exportProperties);
    } else if (gridInstance && args.item.id.includes('excelexport')) {
      gridInstance.excelExport();
    }
  }

  const products = ['סולר', 'בנזין 95', 'אוריה', 'שתיה', 'סגריות'];

  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    console.log(`Year=${selectedYear} Month=${selectedMonth}`);
    console.log(`selectedProducts=${selectedProducts}`);
    console.log(`selectedClients=${selectedClients}`);

  }

  

  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Users List')}</h1>
      <div className="cretria grid3container pt-2 pb-5">

        <MultiSelectComponent
          enableRtl
          placeholder={t('Select Client')}
          dataSource={data?.result}
          fields={{ value: "usr_guid", text: "usr_name" }}
          onChange={obj => setSelectedClients(obj.value)}
        ></MultiSelectComponent>

        <MultiSelectComponent
          enableRtl
          placeholder={t('Select Product')}
          dataSource={products}
          onChange={obj => setSelectedProducts(obj.value)}
        ></MultiSelectComponent>

        <div className="grid2container">
          <DatePickerComponent start="Year" format="MM/yyyy" depth="Year"
            enableRtl
            value={new Date(selectedYear,selectedMonth-1,1)}
            onChange={obj => {
              //console.log(obj)
              setSelectedYear(obj.value?.getFullYear());
              setSelectedMonth(obj.value?.getMonth()+1);
              //console.log(`Year=${selectedYear} Month=${selectedMonth}`)
            }}
          ></DatePickerComponent>
          <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
            {t('Search')}
          </Button>
        </div>
      </div>
      <GridComponent className="mx-0" enableRtl ref={grid => gridInstance = grid} name="mygrid" dataSource={data}
        allowPaging={true}
        pageSettings={{ pageSize: 8 }}
        editSettings={editOptions}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick.bind(this)}
        dataBound={dataBound.bind(this)}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowExcelExport={true}
        allowPdfExport={true}
        allowReordering={true}
        selectionSettings={selectionOptions} rowSelected={rowSelectedEvent} //selectedRowIndex={2}
        // frozenRows={0} frozenColumns={1}
        dataSourceChanged={dataSourceChanged}
        //dataStateChange={dataStateChange.bind(this)}
      >
        
        <ColumnsDirective>
          <ColumnDirective type='checkbox' width='50' />
          {/* <ColumnDirective field='gender' headerText='Gender' textAlign='Right' width='100' />
          <ColumnDirective field='email' headerText='Email' width='150' />
          <ColumnDirective field='nat' headerText='Nationality' /> */}
          <ColumnDirective field='usr_guid' visible={false} headerText='Guid' textAlign='Right' width='100' validationRules={guidRules} isPrimaryKey={true} />
          <ColumnDirective field='usr_mobile' headerText={t('MOBILE')} textAlign='Right' width='100' />
          <ColumnDirective field='usr_name' headerText={t('NAME')} width='150' />
          <ColumnDirective field='usr_email' headerText={t('EMAIL')} />
          <ColumnDirective field='usr_role' headerText={t('ADMIN')} width='150' />
          <ColumnDirective field='usr_status' headerText='Status' width='50' />
          <ColumnDirective headerText='Manage Records' width='160' commands={commands}></ColumnDirective>
        </ColumnsDirective>
        <Inject services={[Page, Filter, Sort, Edit, Resize, Reorder, Scroll, Toolbar, ExcelExport, PdfExport, CommandColumn]} />
      </GridComponent>
    </div>

  )
}

export default UserListScreen
