import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux'
//import Alert from 'react-bootstrap/Alert';

import '../App.css';

import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import FormContainer from '../components/FormContainer';


import { useTranslation } from 'react-i18next';
import Meta from '../components/Meta';
import { axoisConfig, variant_main } from '../constants/generalConstants';

import { appName, appLogo } from '../constants/generalConstants';


const HomeScreen = ({ match }) => {

  //const dispatch = useDispatch()

  const [showAlert, setShowAlert] = useState(false);
  const [query, setQuery] = useState('');
  const history = useHistory();
  const [data, setData] = useState([]);

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  let autoCloseTime = 5000;
  let variant = 'info';
  const [timeLeft, setTimeLeft] = useState(autoCloseTime / 1000);

  const myAppLogo = '/assets/imgs/' + appLogo;

  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {
      loadData();
    }
    // const countdown = setInterval(() => {
    //   if (timeLeft > 1) {
    //     setTimeLeft((prevTime) => prevTime - 1);
    //   } else {
    //     clearInterval(countdown);
    //     setShowAlert(false);
    //     setTimeLeft(autoCloseTime / 1000);
    //   }
    // }, 1000);

    // return () => {
    //   clearInterval(countdown);
    // };
  }, [match]);


  setTimeout(() => {
    setShowAlert(false);
  }, 5000);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleInputChange = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/cards', config);
      //setFilteredOptions(response.data);
      const filteredResults = response.data
        .filter((item) =>
          item.card_name.toLowerCase().includes(term.toLowerCase())
        )
        .map((item) => ({
          name: item.card_name,
          number: item.card_num,
        }));
      setFilteredOptions(filteredResults);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleOptionClick = (option) => {
    setSearchTerm(option.name);
    setQuery(option.number);
    setFilteredOptions([]);
  };


  const loadData = async () => {
    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/cards', config);
      setData(response.data);

    } catch (error) {
      console.log(error);
    }
  };

  // const handleInputChange = (e) => {
  //   setQuery(e.target.value);
  // };

  const submitHandler = (e) => {
    e.preventDefault()
    setShowAlert(false);
    if (userInfo) {
      //history.push(redirect)
      history.push(`/reports/month_car/${query}`);
    } else {
      setTimeLeft(autoCloseTime / 1000);
      setShowAlert(true);
      // Set a timeout to hide the alert after 5 seconds (5000 milliseconds)
      // setTimeout(() => {
      //   setShowAlert(false);
      // }, autoCloseTime);

    }
    //dispatch(login(mobile, password))
  }

  const { t } = useTranslation();



  return (
    <>
      <Meta />

      <FormContainer>
        <div className="row p-5">

          <h1 className=" dir-ltr display-2 fw-bold mb-3 text-capitalize main-color stroke">
            <img thumbnail='true' src={myAppLogo} className='me-1 w-25' width="40" alt="App Logo" />
            <span> Fuel Plus</span>
          </h1>

          {showAlert && (

            // <AutoCloseAlert message={t('You Must Login First')} variant="info" autoCloseTime={5000} />
            <Alert variant={variant} onClose={() => setShowAlert(false)} dismissible>
              <br />
              {t('You Must Login First')}
              <br />
              <small>{t('Auto-closing in')} {Math.floor(timeLeft)} {t('seconds')}...</small>
            </Alert>

          )}
          <Form onSubmit={submitHandler}>
            <input
              type="text"
              placeholder={t('Client name')}
              value={searchTerm}
              onChange={handleInputChange}
              className="srch-input"
            />
            {filteredOptions.length > 0 && (
              <ul className='autocomplete-dropdown' >
                {filteredOptions.map((option, index) => (
                  <li className='autocomplete-item' key={index} onClick={() => handleOptionClick(option)}>
                    {option.name}
                  </li>
                ))}
              </ul>
            )}
            {/* {searchTerm && filteredOptions.length > 0 && (
        <div>
          <p>Selected Card Name: {searchTerm}</p>
          <p>Card Number: {filteredOptions.find((option) => option.name === searchTerm)?.number}</p>
        </div>
      )} */}

            {/* {(query || !userInfo) && ( */}
            <Button className="my-3" type='submit' variant={variant_main} >
              {t('Search Client')}
            </Button>
            {/* )} */}
          </Form>
        </div>
      </FormContainer>
    </>
  )
}

export default HomeScreen
