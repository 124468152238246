import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUser } from '../actions/userActions' //
import { USER_UPDATE_RESET } from '../constants/userConstants'
import { useTranslation } from 'react-i18next';
import { variant_main } from '../constants/generalConstants'


const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id

  const [usr_name, setName] = useState('')
  const [usr_email, setEmail] = useState('')
  const [usr_mobile, setMobile] = useState('')
  const [usr_role, setRole] = useState('')
  const [usr_status, setStatus] = useState('')
  const [usr_message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userUpdate = useSelector((state) => state.userUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET })
      history.push('/admin/userlist')
    } else {
      if (!user.usr_name || user.usr_guid !== userId) {
        dispatch(getUserDetails(userId))
      } else {
        setName(user.usr_name)
        setEmail(user.usr_email)
        setMobile(user.usr_mobile)
        setRole(user.usr_role)
        setStatus(user.usr_status.toUpperCase())
      }
    }
  }, [dispatch, userId, user, history, successUpdate]) // 

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(updateUser({ _id: userId, usr_name, usr_email, usr_mobile, usr_role, usr_status }))
  }

  const { t } = useTranslation();
  return (
    <div className="viewcontainer">
      <Link to='/admin/userlist' className='btn btn-light my-3'>
        {t('Go Back')}
      </Link>
      <FormContainer>
        <h1 className=" dir-ltr display-3 fw-bold mb-3 text-capitalize main-color stroke">Edit User</h1>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='usr_name'>
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control
                type='name'
                placeholder={t('Enter name')}
                value={usr_name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='usr_email'>
              <Form.Label>{t('Email Address')}</Form.Label>
              <Form.Control
                type='email'
                placeholder={t('Enter email')}
                value={usr_email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='usr_mobile'>
              <Form.Label>{t('Mobile')}</Form.Label>
              <Form.Control
                type='number'
                placeholder={t('Enter Mobile')}
                value={usr_mobile}
                onChange={(e) => setMobile(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='usr_role'>
              <Form.Label>{t('Role')}</Form.Label>
              <Form.Select
                type='checkbox'
                label={t('Role')}
                value={usr_role}
                onChange={(e) => setRole(e.target.value)}>
                <option value="admin">{t('Adminstrator')}</option>
                <option value="owner">{t('Owner')}</option>
                <option value="superuser">{t('Superuser')}</option>
                <option value="employee">{t('Employee')}</option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId='usr_status'>
              <Form.Label>{t('Status')}</Form.Label>
              <Form.Select
                type='checkbox'
                label={t('Status')}
                value={usr_status}
                onChange={(e) => setStatus(e.target.value)}>
                <option value="A">{t('Active')}</option>
                <option value="S">{t('Suspended')}</option>
              </Form.Select>
            </Form.Group>


            <Button type='submit' variant={variant_main}>
              {t('Update')}
            </Button>
          </Form>
        )}
      </FormContainer>
    </div>
  )
}

export default UserEditScreen
