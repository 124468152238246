import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { selectItemText } from '../actions/userActions';

import Message from '../components/Messages'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'

import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { appName, axoisConfig } from '../constants/generalConstants';

const BusinessesListScreen = ({ location, history }) => {

    const [items, setItems] = useState([]);
    const dispatch = useDispatch()

    const userLogin = useSelector((state) => state.userLogin)
    const { loading, error, userInfo } = userLogin

    const redirect = location.search ? location.search.split('=')[1] : '/'

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        } else loadData();

    }, [history, userInfo, redirect])

    //company_list
    const loadData = async () => {
        try {

            let config = { ...axoisConfig }

            const response = await axios.get('/api/fuel/company_list', config);
            setItems(response.data);

        } catch (error) {
            console.log(error);
        }
    };

    // const items = [
    //     {
    //         id: 1,
    //         logo: 'assets/imgs/fuelplus.png',
    //         text: 'Item 1 Text',
    //     },
    //     {
    //         id: 2,
    //         logo: 'assets/imgs/fuelplus.png',
    //         text: 'Item 2 Text',
    //     },
    //     // Add more items as needed
    // ];


    function Busniess({ key1, logo, name, desc, prefix, dis_kind }) {
        const handleItemClick = () => {
            // Update localStorage with the selected item's text
            //localStorage.setItem('selectedItemText', text);
            localStorage.setItem(`${appName}_schema`, prefix);
            var name1 = { name, logo };
            localStorage.setItem(`${appName}_schema_name`, JSON.stringify(name1));
            localStorage.setItem(`${appName}_dis_kind`, dis_kind);
            axoisConfig.headers.schema = localStorage.getItem(`${appName}_schema`)

            dispatch(selectItemText(name));

            history.push('login')
        };

        return (
            <div className="item" onClick={handleItemClick}>
                <img
                    thumbnail="true"
                    src={logo}
                    className="me-2 rounded-1"
                    width="40"
                    key={key1}
                    alt={desc}
                />
                <p>{name}</p>
            </div>
        );
    }

    const { t } = useTranslation();

    return (
        <div className="viewcontainer">
            <FormContainer>
                <h1 className="display-5 fw-bold mb-3 text-capitalize main-color stroke">{t('Select Business')}</h1>
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}


                <div className="item-list">
                    {items.map((item) => (
                        <Busniess key1={item.sn} logo={item.logo} name={item.name} desc={item.description} prefix={item.prefix} dis_kind={item.discount_kind} />
                    ))}
                </div>

            </FormContainer>
        </div>
    )
}

export default BusinessesListScreen
