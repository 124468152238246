import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { registerLicense } from '@syncfusion/ej2-base';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Edit, Toolbar, Page, Sort, Filter, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective, ExcelExport, PdfExport } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { PdfTrueTypeFont, PdfFontFamily, PdfFontStyle, PdfStandardFont } from '@syncfusion/ej2-pdf-export';

import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { axoisConfig, variant_main } from '../constants/generalConstants'
import { Button } from 'react-bootstrap'

import { adventProFont } from '../font';

import { useTranslation } from 'react-i18next';
import e from 'cors';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');

const ManualAddScreen = ({ history }) => {
  const [data, setData] = useState([]);
  // const [cards, setCards] = useState([]);
  // const [products, setProducts] = useState([]);
  const gridRef = useRef(null);

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  //
  const [allowEdit, setAllowEdit] = useState(true);
  const [dataCards, setDataCards] = useState([]);
  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedPump, setselectedPump] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  const month = ((new Date()).getMonth().toString()) + '/';
  const date = ((new Date()).getDate().toString()) + '/';
  const year = ((new Date()).getFullYear().toString());

  const formattedDate = (d) => {
    const cd = num => num.toString().padStart(2, 0);
    return d.getFullYear() + "/" + cd(d.getMonth() + 1) + "/" + cd(d.getDate());
  }

  let previousDate = formattedDate(new Date());

  const loadData = async () => {
    try {

      const data = {
        // JSON data to be sent in the request body
        m1: selectedMonth + 1,
        y1: selectedYear,
        pumb1: selectedPump,
        cardnum1: selectedClient
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const Response = await axios.post('/api/fuel/last_manual_movs', data, config);
      setData(Response.data);
      //setData(static_cards);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const loadDataCards = async () => {
    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/fuel/clients', config);
      let data1 = response.data
      const newElement = { card_num: 0, card_name: `${t('ALL')}` };
      data1.unshift(newElement);
      //data1.push(newElement);

      setDataCards(data1);

      // .then(response => 
      //   response.json()
      // )
      //   .then(result => {
      //     return { "result": result, "count": result.length };
      //   })

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    loadDataCards();
    loadData();

    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

    var buttonWithAdd = findButtonWithAdd(); // document.querySelector('input[innerText="Add"]');
    if (buttonWithAdd) {
      document.addEventListener("keydown", function (event) {

        if (event.key === "+") {
          // Simulate a click on the button
          event.preventDefault();
          buttonWithAdd.click();
          var input = document.querySelector('input[name="rsm_date"]');

          if (input) {
            input.value = previousDate;
            var inputName = document.querySelector('input[name="rsm_card_name"]');
            // TBD: inputName focus
            inputName?.focus();
          }

        }
      });
    }

  }, [history]);


  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  function findButtonWithAdd() {
    var buttons = document.querySelectorAll('button');

    // Loop through the buttons to find the one with a span containing "Add"
    var buttonWithAdd = null;
    for (var i = 0; i < buttons.length; i++) {
      var button = buttons[i];

      if (button.innerText === "Add") {
        buttonWithAdd = button;
        break; // Stop searching once found
      }
    }
    return buttonWithAdd;
  }

  const toolbarOptions = ['Add', 'Edit', 'Update', 'Delete', 'Cancel', 'ExcelExport']; // , 'PdfExport', 'Print'];
  const selectionOptions = { type: 'Multiple' };

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true, showDeleteConfirmDialog: true, mode: 'Normal' //, showConfirmDialog: false
  }


  const handleBeforeBatchAdd = (args) => {
    var input = document.querySelector('input[name="rsm_date"]');
    if (input) {
      previousDate = input.value;
    }
  }

  const handleActionBegin = (args) => {

    if (args.requestType === "beginEdit") { // && args.action === 'edit'    
      setAllowEdit(false);
      //   gridInstance.columns[2].allowEditing=false;   
    } else if (args.requestType === "add") {
      setAllowEdit(true);
      //     gridInstance.columns[2].allowEditing=true;   
    } else if (args.requestType === "cancel") {
      setAllowEdit(true);
    }

  }

  const handleActionComplete = (args) => {
    let config = { ...axoisConfig }
    config.headers.Authorization = `${userInfo.token}`;

    if (args.requestType === 'save' && args.action === 'add') {
      setAllowEdit(false);
      let dt1 = new Date(args.data.rsm_date);
      dt1.setHours(dt1.getHours() + 10);
      args.data.rsm_date = dt1;

      //Insert New Records     
      axios.post('/api/fuel/AddManual', args.data, config)
        .then((response) => {
          console.log(data);
          let tmpdata = data;
          //tmpdata.splice(0, 1, response.data[0]);
          tmpdata[0].rsm_sn = response.data[0].rsm_sn;
          setData(tmpdata);
        })
        .catch((error) => {
          console.error('Error adding data:', error);
        });
      //alert('Add New Record');
    } else if (args.requestType === 'save' && args.action === 'edit') {
      // Save the new records to the server here if needed
      setAllowEdit(false);
      let dt1 = new Date(args.data.rsm_date);
      dt1.setHours(dt1.getHours() + 10);
      args.data.rsm_date = dt1;
      const targetCard = dataCards.find(item => item.card_name === args.data.rsm_card_name);
      if (targetCard) {
         args.data.rsm_card_num = targetCard.card_num;
      } else args.data.rsm_card_num =0;
      

      axios.put(`/api/fuel/AddManual/${args.data.rsm_sn}`, args.data, config)
        .then((response) => {
          console.log('Update Record Success');
          console.log(response.data[0]);

        })
        .catch((error) => {
          console.error('Error adding data:', error);
        });
    } else if (args.requestType === 'delete') {
      alert('delete');
      axios.delete(`/api/fuel/AddManual/${args.data[0].rsm_sn}`, config)
        .catch((error) => {
          console.error('Error deleting data:', error);
        });
    }
  };


  const createCustomerIDFn = () => {
    inpuEle = document.createElement('input');
    return inpuEle;
  };
  const destroyCustomerIDFn = () => {
    autoCompleteIns.destroy();
  };
  const readCustomerIDFn = () => {
    const tt = allowEdit;
    return autoCompleteIns.value;
  };
  const writeCustomerIDFn = async (args) => {

    try {
      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;
      const cardsResponse = await axios.get('/api/cards', config);

      autoCompleteIns = new AutoComplete({
        allowCustom: false,
        // enabled: allowEdit,
        value: args.rowData[args.column.field],
        dataSource: cardsResponse.data,
        fields: { value: 'card_name', text: 'card_name' },
      });
      autoCompleteIns.appendTo(args.element);
      // var inputName = document.querySelector('input[id="mygridrsm_card_name"]');
      // if (inputName) inputName.readOnly=!allowEdit;


    } catch (error) {
      console.error("Error fetching cards data:", error);
    }
  };


  const createProductFn = () => {
    inpuEleProduct = document.createElement('input');
    return inpuEleProduct;
  };
  const destroyProductFn = () => {
    autoCompleteInsProduct.destroy();
  };
  const readProductFn = () => {
    return autoCompleteInsProduct.value;
  };
  const writeProductFn = async (args) => {

    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const productsResponse = await axios.get('/api/products', config);

      autoCompleteInsProduct = new AutoComplete({
        allowCustom: false,
        value: args.rowData[args.column.field],
        dataSource: productsResponse.data,
        fields: { value: 'prd_name', text: 'prd_name' },
      });
      autoCompleteInsProduct.appendTo(args.element);
    } catch (error) {
      console.error("Error fetching Products data:", error);
    }
  };


  let inpuEle;
  let autoCompleteIns;

  let inpuEleProduct;
  let autoCompleteInsProduct;

  const daParams = {
    create: createCustomerIDFn,
    destroy: destroyCustomerIDFn,
    read: readCustomerIDFn,
    write: writeCustomerIDFn
  };

  const daPrParams = {
    create: createProductFn,
    destroy: destroyProductFn,
    read: readProductFn,
    write: writeProductFn
  };
  const { t } = useTranslation();
  const NumericRequiredValidator = { required: true, number: true };
  const RequiredValidator = { required: true, minLength: 2 };

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    console.log(`Year=${selectedYear} Month=${selectedMonth + 1}`);
    console.log(`selectedPumb=${selectedPump}`);
    console.log(`selectedClients=${selectedClient}`);
    // refreshGrid(userInfo);
    loadData();
  }

  const handleDateChange = (args) => {
    setSelectedYear(args.value?.getFullYear());
    setSelectedMonth(args.value?.getMonth());
  };

  const handleClientValueChange = (args) => {
    const newSelectedItems = args.value; // Array of selected items
    setSelectedClient(newSelectedItems);
  };

  const handleBlur = () => {
    // Perform any validation or processing here before updating the state
    const numericValue = parseFloat(selectedPump); // Convert the value to a numeric format
    if (!isNaN(numericValue)) {
      setselectedPump(numericValue); // Round the numeric value to 2 decimal places
    }
  };

  const handleChange = (event) => {
    // Allow only numeric values and a dot (for decimals)
    const newValue = event.target.value.replace(/[^0-9.]/g, '');

    if (newValue.length > 8) {
      newValue = newValue.slice(0, 8);
    }
    setselectedPump(newValue);
  };


  const inputStyle = {
    border: 'none',
    outline: 'none', // Remove the blue outline on focus
    borderBottom: '1px solid #ccc', // Add a bottom border for a subtle separator
    width: '100%',
    padding: '5px', // Adjust padding as needed 
    textAlign: 'right'
  };

  function footerSum(props) {
    return (<span>{t('Sum')}: {props.Sum}</span>);
  }

  let gridInstance;
  const exportProperties = {
    fileName: 'invoice.pdf',
    exportType: 'CurrentPage'
  };
  const toolbarClick = (args) => {
    if (gridInstance && args.item.id.includes('pdfexport')) {
      gridInstance.columns[8].visible = false;
      setSelectedRecords(gridInstance.getSelectedRecords());
      gridInstance.pdfExport(getPdfExportProperties()); //exportProperties

    } else if (gridInstance && args.item.id.includes('excelexport')) {
      gridInstance.excelExport();
    }
  }

  const pdfExportComplete = () => {
    if (gridInstance) {
      gridInstance.columns[8].visible = false;
    }
  };

  /* tslint:disable-next-line:no-any */

  /* tslint:disable-next-line:no-any */
  function getPdfExportProperties() {
    return {
      //new PdfStandardFont(PdfFontFamily.TimesRoman, 9)
      // pageOrientation: 'Landscape',
      theme: {
        caption: { font: new PdfTrueTypeFont(adventProFont, 11) },
        header: { font: new PdfTrueTypeFont(adventProFont, 11) },
        record: { font: new PdfTrueTypeFont(adventProFont, 10) }  //PdfFontFamily
        // header: { bold: true,  fontName: 'Times new roman',  fontSize: 15},       
        // record: { bold: true,  fontName: 'Times new roman',  fontSize: 15 } //font: new PdfTrueTypeFont(adventProFont, 9)
      },
      header: {
        fromTop: 0,
        height: 120,
        contents: [
          {
            type: 'Text',
            value: 'INVOICE',
            // font: new PdfStandardFont(PdfFontFamily.TimesRoman, 9), 
            position: { x: 280, y: 0 },
            style: { textBrushColor: '#C25050', fontSize: 25 },
          },
          {
            type: 'Text',
            value: 'INVOICE NUMBER',
            position: { x: 500, y: 30 },
            style: { textBrushColor: '#C67878', fontSize: 10 },
          },
          {
            type: 'Text',
            value: 'Date',
            position: { x: 600, y: 30 },
            style: { textBrushColor: '#C67878', fontSize: 10 },
          }, {
            type: 'Text',
            value: '223344',
            position: { x: 500, y: 50 },
            style: { textBrushColor: '#000000', fontSize: 10 },
          },
          {
            type: 'Text',
            value: (month + date + year).toString(),
            position: { x: 600, y: 50 },
            style: { textBrushColor: '#000000', fontSize: 10 },
          },
          {
            type: 'Text',
            value: 'CUSTOMER ID',
            position: { x: 500, y: 70 },
            style: { textBrushColor: '#C67878', fontSize: 10 },
          },
          {
            type: 'Text',
            value: 'TERMS',
            position: { x: 600, y: 70 },
            style: { textBrushColor: '#C67878', fontSize: 10 },
          }, {
            type: 'Text',
            value: '223',
            position: { x: 500, y: 90 },
            style: { textBrushColor: '#000000', fontSize: 10 },
          },
          {
            type: 'Text',
            value: 'Net 30 days',
            position: { x: 600, y: 90 },
            style: { textBrushColor: '#000000', fontSize: 10 },
          },
          {
            type: 'Text',
            value: 'Adventure Traders',
            position: { x: 20, y: 30 },
            style: { textBrushColor: '#C67878', fontSize: 20 }
          },
          {
            type: 'Text',
            value: ' פאטרול محطة وقود',
            font: new PdfTrueTypeFont(adventProFont, 10),
            position: { x: 20, y: 65 },
            style: { textBrushColor: '#000000', fontSize: 11 }
          },
          {
            type: 'Text',
            value: 'טל :  +972 5555555',
            font: new PdfTrueTypeFont(adventProFont, 10),
            position: { x: 20, y: 80 },
            style: { textBrushColor: '#000000', fontSize: 11 }
          },
        ]
      },
      footer: {
        fromBottom: 160,
        height: 100,
        contents: [
          {
            type: 'Text',
            value: 'Thank you for your business !',
            position: { x: 250, y: 20 },
            style: { textBrushColor: '#C67878', fontSize: 14 }
          },
          {
            type: 'Text',
            value: '! Visit Again !',
            position: { x: 300, y: 45 },
            style: { textBrushColor: '#C67878', fontSize: 14 }
          },
          {
            format: 'Page {$current} of {$total}',
            pageNumberType: 'Arabic',
            position: { x: 0, y: 25 },
            style: {
              fontSize: 15,
              textBrushColor: '#000080'
            },
            type: 'PageNumber'
          }
        ]
      },
      fileName: 'invoice.pdf',
      exportType: 'CurrentPage',
      dataSource: selectedRecords
    };
  }

  const pdfHeaderQueryCellInfo = (args) => {
    args.cell.row.pdfGrid.repeatHeader = true;
  };

  const filterOptions = {
    ignoreAccent: true,
    type: 'Excel'
  };

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'Israel',
    timeZoneName: 'short',
  };




  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Manual Add')}</h1>
      <div className="cretria grid4container pt-2 pb-5 ps-2">

        <input type='number' name='pump1' onBlur={handleBlur} onChange={handleChange}
          placeholder={t('Enter a Pumb')} style={inputStyle} value={selectedPump} ></input>

        <DropDownListComponent id="atcelement"
          enableRtl
          placeholder={t('Select Client')}
          dataSource={dataCards}
          fields={{ value: "card_num", text: "card_name" }}
          change={handleClientValueChange}
        ></DropDownListComponent>

        <DatePickerComponent start="Year" format="MM/yyyy" depth="Year"
          enableRtl
          value={new Date(selectedYear, selectedMonth, 1)}
          change={handleDateChange}
        ></DatePickerComponent>
        <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
          {t('Search')}
        </Button>


      </div>

      <GridComponent height={200} ref={grid => gridInstance = grid} id="mygrid" name="mygrid" enableRtl
        allowPaging={true}
        allowAdding={true}
        allowEditing={true}
        allowFiltering={true}
        allowSorting={true}
        toolbar={toolbarOptions}
        editSettings={editOptions}
        dataSource={data}
        pageSettings={{ pageSize: 25 }}
        beforeBatchAdd={handleBeforeBatchAdd}
        actionBegin={handleActionBegin}
        actionComplete={handleActionComplete}
        printMode='CurrentPage'
        allowExcelExport={true}
        allowPdfExport={true}
        filterSettings={filterOptions}
        pdfExportComplete={pdfExportComplete}
        pdfHeaderQueryCellInfo={pdfHeaderQueryCellInfo}
        selectionSettings={selectionOptions}
        toolbarClick={toolbarClick.bind(this)} >

        <ColumnsDirective>
          <ColumnDirective field='rsm_sn' headerText={t('rsm_sn')} isPrimaryKey={true} visible={false} width='10'></ColumnDirective>
          <ColumnDirective field="rsm_date" headerText={t('rsm_date')} width="120" textAlign="Right" type="date" format="dd/MM/yyyy" editType="datepickeredit" />
          <ColumnDirective field="rsm_card_name" headerText={t('rsm_card_name')} width="180" textAlign="Right" edit={daParams} validationRules={RequiredValidator} />
          <ColumnDirective field="rsm_tap" headerText={t('rsm_shovar')} type="string" width="80" textAlign="Right" validationRules={NumericRequiredValidator} />
          <ColumnDirective field="rsm_pump" headerText={t('rsm_pnkas')} type="string" width="80" textAlign="Right" validationRules={NumericRequiredValidator} />
          <ColumnDirective field="rsm_product" headerText={t('rsm_product')} type="string" width="180" textAlign="Right" edit={daPrParams} validationRules={RequiredValidator} />
          <ColumnDirective field="rsm_qnt" headerText={t('rsm_qnt')} width="100" textAlign="Right" format="C2" validationRules={NumericRequiredValidator} />
          <ColumnDirective field="rsm_total" headerText={t('rsm_total')} width="100" textAlign="Right" format="C2" validationRules={NumericRequiredValidator} />
          <ColumnDirective field="rsm_src" headerText={t('rsm_src')} width="1" defaultValue='manual' textAlign="Right" type='string' />
        </ColumnsDirective>
        <AggregatesDirective>
          <AggregateDirective>
            <AggregateColumnsDirective>
              <AggregateColumnDirective field='rsm_qnt' type='Sum' format='N2' footerTemplate={footerSum} > </AggregateColumnDirective>
              <AggregateColumnDirective field='rsm_total' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
            </AggregateColumnsDirective>
          </AggregateDirective>

        </AggregatesDirective>
        <Inject services={[Edit, Toolbar, Page, Aggregate, Sort, Filter, ExcelExport, PdfExport]} />
      </GridComponent>

    </div>
  );
};

export default ManualAddScreen;
