
import axios from 'axios';
import { axoisConfig, variant_main } from '../constants/generalConstants'

export const getOrders = async (userInfo) => {

  const url = '/api/users'; // Replace with the actual API endpoint URL

  let config = { ...axoisConfig }
  config.headers.Authorization = `${userInfo.token}`;

  // const headers = {
  //   'Content-Type': 'application/json',
  //   'Authorization': userInfo.token,
  // };

  try {
    const response = await axios.get(url, config);
    const result = response.data;
    return { "result": result, "count": result.length };
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error so that the caller can handle it
  }

}

export const getMonthlyCustsSum_ByProduct = async (userInfo, month1, year1) => {
  const url = '/api/fuel/rpt_month_custs_product'; // Replace with the actual API endpoint URL

  // const headers = {
  //   'Content-Type': 'application/json',
  //   'Authorization': userInfo.token,
  // };

  let config = { ...axoisConfig }
  config.headers.Authorization = `${userInfo.token}`;

  try {
    const response = await axios.post(url, { m1: month1, y1: year1 }, config);
    const result = response.data;
    return { "result": result, "count": result.length };
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error so that the caller can handle it
  }
};

//add
export const addOrder = async (userInfo, order) => {
  const url = '/api/users'; // Replace with the actual API endpoint URL

  // const headers = {
  //   'Content-Type': 'application/json',
  //   'Authorization': userInfo.token,
  // };

  let config = { ...axoisConfig }
  config.headers.Authorization = `${userInfo.token}`;

  try {
    const response = await axios.post(url, { value: order }, config);
    const result = response.data;
    return { "result": result };
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error so that the caller can handle it
  }
};

//update usr_guid
export const updateOrder = async (userInfo, user) => {
  const url = '/api/users/' + user.usr_guid; // Replace with the actual API endpoint URL

  // const headers = {
  //   'Content-Type': 'application/json',
  //   'Authorization': userInfo.token,
  // };

  
  let config = { ...axoisConfig }
  config.headers.Authorization = `${userInfo.token}`;

  try {
    const response = await axios.put(url, { value: user }, config);
    const result = response.data;
    return { "result": result, "count": 1 };
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error so that the caller can handle it
  }
};

//delete
export const deleteOrder = async (userInfo, user) => {
  const url = '/api/users/' + user.usr_guid; // Replace with the actual API endpoint URL

  // const headers = {
  //   'Content-Type': 'application/json',
  //   'Authorization': userInfo.token,
  // };

  
  let config = { ...axoisConfig }
  config.headers.Authorization = `${userInfo.token}`;
  
  try {
    const response = await axios.delete(url, { value: user }, config);
    const result = response.data;
    //return { "result": result, "count": 1 };
    return result
  } catch (error) {
    console.error(error);
    throw error; // Re-throw the error so that the caller can handle it
  }
};

// export function (userInfo) {
  // return fetch('/api/users', {
  //   method: "GET", // *GET, POST, PUT, DELETE, etc.
  //   mode: "cors", // no-cors, *cors, same-origin
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Authorization" : userInfo.token
  //     // 'Content-Type': 'application/x-www-form-urlencoded',
  //   }
  // }).then(response => 
  //   response.json()
  // )
  //   .then(result => {
  //     return { "result": result, "count": result.length };
  //   })
  
// export function addOrder(userInfo, order) {
//   return fetch('/api/users', {
//     method: "POST", // *GET, POST, PUT, DELETE, etc.
//     mode: "cors", // no-cors, *cors, same-origin
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization" : userInfo.token
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: JSON.stringify({ value: order }), // body data type must match "Content-Type" header
//   }).then(response => {
//     response.text()
//   })
//     .then(result => {
//       return result
//     });
// }


// export function updateOrder(userInfo, user) {
//   return fetch('/api/users/' + user.usr_guid, {
//     method: "PUT", // *GET, POST, PUT, DELETE, etc.
//     mode: "cors", // no-cors, *cors, same-origin
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization" : userInfo.token
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: JSON.stringify({ value: user }), // body data type must match "Content-Type" header
//   }).then(response => 
//     response.json()
//   )
//     .then(result => {
//       return { "result": result, "count": 1 };
//     });
// }


// export function deleteOrder(userInfo, order) {
//   return fetch('/api/users/' + order.usr_guid, {
//     method: "DELETE", // *GET, POST, PUT, DELETE, etc.
//     mode: "cors", // no-cors, *cors, same-origin
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization" : userInfo.token
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: JSON.stringify({ value: order }), // body data type must match "Content-Type" header
//   }).then(response => {
//     response.text()
//   })
//     .then(result => {
//       return result
//     });
// }

// export function getMonthlyCustsSum_ByProduct(userInfo, month1, year1) {
//   return fetch('/api/fuel/rpt_month_custs_product', {
//     method: "POST", // *GET, POST, PUT, DELETE, etc.
//     mode: "cors", // no-cors, *cors, same-origin
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization" : userInfo.token
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     body: JSON.stringify({
//       m1: month1,
//       y1: year1,
//     })
//   }).then(response => 
//     response.json()
//   )
//     .then(result => {
//       return { "result": result, "count": result.length };
//     })
// }