// MyComponent.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const divStyle = {
  width: '100%',
  direction: 'rtl',
  textAlign: 'right'
};

const tblStyle = {
  width: '100%',
  direction: 'rtl',
  textAlign: 'right',
  border: '1px'
};

const tblSumStyle = {
  width: '50%',
  direction: 'rtl',
  textAlign: 'center',
  border: '1px'
};

const headerStyle = {
  color: 'darkblue',
  backgroundColor: '#efe520'
}

const footerStyle = {
  borderColor: '#e0e0e0',
  color: '#000000',
  backgroundColor: '#deecf9'
}

const MyComponent = ({ name, data }) => {

  const { t } = useTranslation();

  const calculateColumnSum = (columnName) => {
    const sum = data.reduce((sum, item) => sum + (item[columnName] || 0), 0);
    return sum.toFixed(2);
  };

  const aggregateRows = (result, row) => {

    const { rsm_card_num, rsm_product, rsm_qnt, rsm_total, rsm_total_final } = row;

    if (!result[rsm_card_num]) {
      result[rsm_card_num] = {
        qnt1: 0, price1: 0, priceh1: 0,
        qnt2: 0, price2: 0, priceh2: 0,
        qnt3: 0, price3: 0, priceh3: 0,
      };
    }

    if (rsm_product == "סולר") {
      result[rsm_card_num].qnt1 += rsm_qnt;
      result[rsm_card_num].price1 += rsm_total;
      result[rsm_card_num].priceh1 += rsm_total_final;
    } else if (rsm_product == "בנזין 95") {
      result[rsm_card_num].qnt2 += rsm_qnt;
      result[rsm_card_num].price2 += rsm_total;
      result[rsm_card_num].priceh2 += rsm_total_final;
    } else {
      result[rsm_card_num].qnt3 += rsm_qnt;
      result[rsm_card_num].price3 += rsm_total;
      result[rsm_card_num].priceh3 += rsm_total_final;
    }

    return result;
  };

  const aggregatedResult = data.reduce(aggregateRows, {});
  const finalAgg = aggregatedResult[data[0].rsm_card_num];
  console.log(aggregatedResult);

  return (
    <div style={divStyle}>
      <h2>{name}</h2>
      <table style={tblStyle}>
        <thead>
          <tr style={headerStyle}>
            <th>{t('rsm_date')}</th>
            <th>{t('rsm_tap')}</th>
            <th>{t('rsm_shovar')}</th>
            <th>{t('rsm_car_plate')}</th>
            <th>{t('rsm_internal')}</th>
            <th>{t('rsm_car_name')}</th>
            <th>{t('rsm_pump')}</th>
            <th>{t('rsm_tap')}</th>
            <th>{t('rsm_product')}</th>
            <th>{t('rsm_qnt')}</th>
            <th>{t('rsm_total')}</th>
            <th>{t('discount')}</th>
            <th>{t('rsm_total_final')}</th>
            <th>{t('rsm_price')}</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.rsm_Isr_date}</td>
              <td>{item.rsm_tap}</td>
              <td>{item.rsm_shovar}</td>
              <td>{item.rsm_car_plate}</td>
              <td>{item.rsm_internal}</td>
              <td>{item.rsm_car_name}</td>
              <td>{item.rsm_pump}</td>
              <td>{item.rsm_tap}</td>
              <td>{item.rsm_product}</td>
              <td>{item.rsm_qnt}</td>
              <td>{item.rsm_total}</td>
              <td><b>{item.card_discount}</b></td>
              <td>{item.rsm_total_final}</td>
              <td>{item.rsm_price}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr style={footerStyle}>
            <td colSpan={9}> </td>
            <td >{calculateColumnSum('rsm_qnt')}</td>
            <td >{calculateColumnSum('rsm_total')}</td>
            <td ></td>
            <td >{calculateColumnSum('rsm_total_final')}</td>
            <td ></td>
          </tr>
        </tfoot>
      </table>
      <br/>
      <table style={tblSumStyle}>
        <thead>
          <tr style={headerStyle}>
            <th>{t('rsm_product')}</th>
            <th>{t('rsm_qnt')}</th>
            <th>{t('rsm_total')}</th>
            <th>{t('rsm_total_final')}</th>
          </tr>
          <tr key={1}>
            <td>סולר</td>
            <td>{finalAgg.qnt1.toFixed(2)}</td>
            <td>{finalAgg.price1.toFixed(2)}</td>
            <td>{finalAgg.priceh1.toFixed(2)}</td>
          </tr>
          <tr key={2}>
            <td>בנזין 95</td>
            <td>{finalAgg.qnt2.toFixed(2)}</td>
            <td>{finalAgg.price2.toFixed(2)}</td>
            <td>{finalAgg.priceh2.toFixed(2)}</td>
          </tr>
          <tr key={2}>
            <td>אחר</td>
            <td>{finalAgg.qnt3.toFixed(2)}</td>
            <td>{finalAgg.price3.toFixed(2)}</td>
            <td>{finalAgg.priceh3.toFixed(2)}</td>
          </tr>
        </thead>
      </table>

    </div>
  );
};

export default MyComponent;