import React, { useEffect, useState } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Edit, Toolbar, Page } from '@syncfusion/ej2-react-grids';
import axios from 'axios';
import { axoisConfig, variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next';

registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');

const ProductListScreen = ({ history }) => {
    const [data, setData] = useState([]);
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (userInfo && userInfo.usr_role === 'admin') {
            loadData();
        } else {
            history.push('/login')
        }
        // Example usage
        // const element = findElementWithText("To remove the license validation");
        // if (element) {
        //     console.log("Element found:", element);
        //     element.style.display = 'none';
        //     element.parentNode.removeChild(element);
        // } else {
        //     console.log("Element not found.");
        // }
    }, [history]);

    const editOptions = {
        allowEditing: true, allowAdding: true, allowDeleting: true
    }
    const toolbarOptions = ['Add', 'Edit', 'Update', 'Cancel'];


    const { t } = useTranslation();

    const loadData = async () => {
        try {

            let config = { ...axoisConfig }
            config.headers.Authorization = `${userInfo.token}`;

            const response = await axios.get('/api/products', config);
            setData(response.data);

        } catch (error) {
            console.log(error);
        }
    };;

    const handleActionComplete = (args) => {

        let config = { ...axoisConfig }
        config.headers.Authorization = `${userInfo.token}`;

        if (args.requestType === 'save') {
            // Add new data when the "Add" operation is completed
            if (args.action === 'add') {
                axios.post('/api/products', args.data, config)
                    .then((response) => {
                        setData(prevData => [...prevData, response.data]);
                    })
                    .catch((error) => {
                        console.error('Error adding data:', error);
                    });
            }
            // Update existing data when the "Edit" operation is completed
            else if (args.action === 'edit') {
                axios.put(`/api/products/${args.data.prd_sn}`, args.data, config)
                    .catch((error) => {
                        console.error('Error updating data:', error);
                    });
            }
            // Delete data when the "Delete" operation is completed
            else if (args.action === 'delete') {
                axios.delete(`/api/records/${args.data.prd_sn}`, config)
                    .catch((error) => {
                        console.error('Error deleting data:', error);
                    });
            }
        }
    };


    return (

        <div className="viewcontainer">
        <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Product List')}</h1>
   
        <GridComponent enableRtl dataSource={data}
            editSettings={editOptions}
            toolbar={toolbarOptions}
            allowPaging={true}
            allowAdding={true}
            allowEditing={true}
            allowDeleting={true}
            pageSettings={{ pageSize: 10, pageSizes: true, pageCount: 4 }}
            actionComplete={handleActionComplete}>
            <ColumnsDirective>
                <ColumnDirective field='prd_sn' headerText='prd_sn' isPrimaryKey={true} visible={false} width='100'></ColumnDirective>
                <ColumnDirective field='prd_name' headerText={t('prd_name')} width='150'></ColumnDirective>
            </ColumnsDirective>
            <Inject services={[Edit, Toolbar, Page]} />
        </GridComponent>
        </div>
    );
};

export default ProductListScreen;
