import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getUserDetails, updateUserProfile } from '../actions/userActions'

import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { useTranslation } from 'react-i18next';
import { variant_main } from '../constants/generalConstants'

const ProfileScreen = ({ history }) => {
  const [usr_name, setName] = useState('')
  const [usr_email, setEmail] = useState('')
  const [usr_password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success, error: errorUpdate } = userUpdateProfile


  useEffect(() => {
    if (!userInfo) {
      history.push('/login')
    } else {
      if (!user || !user.usr_name || user.usr_guid !== userInfo.usr_guid || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET })
        dispatch(getUserDetails(userInfo.usr_guid))

      } else {
        setName(user.usr_name)
        setEmail(user.usr_email)

      }
    }
  }, [dispatch, history, userInfo, user, success])

  const submitHandler = (e) => {
    e.preventDefault()
    if (usr_password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      setMessage('')
      dispatch(updateUserProfile({ usr_guid: user.usr_guid, usr_name: usr_name, usr_email: usr_email, usr_password: usr_password }))
    }
  }
  const { t } = useTranslation();

  return (
    <div className="viewcontainer">
      <FormContainer>
        <h1 className="display-5 fw-bold mb-3 text-capitalize main-color stroke">{t('User Profile')}</h1>
        {message ? (<Message variant='danger'>{message}</Message>) :
          (errorUpdate && <Message variant='danger'>{errorUpdate}</Message>)}
        { }
        {success && <Message variant='success'>{t('Profile Updated')}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='usr_name'>
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control
                type='name'
                placeholder={t('Enter name')}
                value={usr_name}
                onChange={(e) => setName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='usr_email'>
              <Form.Label>{t('Email Address')}</Form.Label>
              <Form.Control
                type='email'
                placeholder={t('Enter email')}
                value={usr_email}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='usr_password'>
              <Form.Label>{t('Password')}</Form.Label>
              <Form.Control
                type='password'
                placeholder={t('Password')}
                value={usr_password}
                onChange={(e) => setPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId='confirmPassword'>
              <Form.Label>{t('Confirm password')}</Form.Label>
              <Form.Control
                type='password'
                placeholder={t('Confirm password')}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Button className="my-3" type='submit' variant={variant_main}>
              {t('Update')}
            </Button>
          </Form>
        )}
      </FormContainer>
    </div>
  )
}

export default ProfileScreen
