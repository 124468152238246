import React, { useEffect, useState, useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Edit, Toolbar, Page, Sort, Filter } from '@syncfusion/ej2-react-grids';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import axios from 'axios';
import { axoisConfig, variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next';

registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');

const CardListScreen = ({ history }) => {
    const [data, setData] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastColor, setToastColor] = useState('');

    //const [selectedClientArray, setSelectedClientArray] = useState([]); //setSelectedClient
    const [selectedSolar, setSelectedSolar] = useState(-1);
    const [selectedBanz, setSelectedBanz] = useState(-1);
   
    const gridRef = useRef(null);
    let grid;

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (userInfo && userInfo.usr_role === 'admin') {
            loadData();
        } else {
            history.push('/login')
        }
        // Example usage
        // const element = findElementWithText("To remove the license validation");
        // if (element) {
        //     console.log("Element found:", element);
        //     element.style.display = 'none';
        //     element.parentNode.removeChild(element);
        // } else {
        //     console.log("Element not found.");
        // }
    }, [history]);

    const editOptions = {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' //, showConfirmDialog: false
    }

    const toolbarOptions = ['Add', 'Edit', 'Update', 'Cancel'];
    const rangeValid = { range: [0, 100] };
    const requireValid = { required: true };

    const { t } = useTranslation();

    const loadData = async () => {
        try {

            let config = { ...axoisConfig }
            config.headers.Authorization = `${userInfo.token}`;

            const response = await axios.get('/api/cards', config);
            setData(response.data);

        } catch (error) {
            console.log(error);
        }
    };

    //UpdDiscount
    const updateDiscount = async (clientArray) => {
        try {
            const data = {
                cardnum1: clientArray,
                discount1: selectedSolar,
                discount2: selectedBanz
                // Add more key-value pairs as needed
            };

            let config = { ...axoisConfig }
            config.headers.Authorization = `${userInfo.token}`;

            const response = await axios.post('/api/cards/UpdDiscount', data, config);
            console.log(response);

        } catch (error) {
            console.log(error);
        }
    };


    //let array=[];
    const onCellSaved = (args) => {
        //    console.log(args);
        //   array.push(parseInt(args.cell.getAttribute('aria-colindex')) + 'of' + parseInt(args.cell.getAttribute('index'))) 
    };

    const handleShowToast = (text, color) => {
        setToastText(text);
        setToastColor(color);
        setShowToast(true);
    };

    const handleActionComplete = (args) => {

        let config = { ...axoisConfig }
        config.headers.Authorization = `${userInfo.token}`;

        if (args.requestType === 'save') {
            // Add new data when the "Add" operation is completed
            if (args.action === 'add') {
                axios.post('/api/cards', args.data, config)
                    .then((response) => {
                        setData(prevData => [...prevData, response.data]);
                        handleShowToast(t('Added Succesfully'), 'success');

                    })
                    .catch((error) => {
                        console.error('Error adding data:', error);
                        handleShowToast(t(error.response.data), 'danger');
                        //Card already registered
                    });
            }
            // Update existing data when the "Edit" operation is completed
            else if (args.action === 'edit') {
                axios.put(`/api/cards/${args.data.card_sn}`, args.data, config)
                    .catch((error) => {
                        console.error('Error updating data:', error);
                    });
            }
            // Delete data when the "Delete" operation is completed
            else if (args.action === 'delete') {
                axios.delete(`/api/cards/${args.data.card_sn}`, config)
                    .catch((error) => {
                        console.error('Error deleting data:', error);
                    });
            }
            //""            
        } else if (args.requestType === 'batchsave') {
            // console.log(args);
            // const modifiedData = gridRef.current.getBatchChanges();
            // console.log(modifiedData);
            // // axios.post(`/api/cards/batch`, args.data, config)
            //     .catch((error) => {
            //         console.error('Error updating data:', error);
            //     });
        }
    };

    const handleBlur = () => {
        // Perform any validation or processing here before updating the state
        const numericValue = parseFloat(selectedSolar); // Convert the value to a numeric format
        if (!isNaN(numericValue)) {
            setSelectedSolar(numericValue); // Round the numeric value to 2 decimal places
        }
    };

    const handleChange = (event) => {
        // Allow only numeric values and a dot (for decimals)
        const newValue = event.target.value.replace(/[^0-9.]/g, '');

        if (newValue.length > 8) {
            newValue = newValue.slice(0, 8);
        }
        setSelectedSolar(newValue);
    };

    const handleBlurB = () => {
        // Perform any validation or processing here before updating the state
        const numericValue = parseFloat(selectedBanz); // Convert the value to a numeric format
        if (!isNaN(numericValue)) {
            setSelectedBanz(numericValue); // Round the numeric value to 2 decimal places
        }
    };

    const handleChangeB = (event) => {
        // Allow only numeric values and a dot (for decimals)
        const newValue = event.target.value.replace(/[^0-9.]/g, '');

        if (newValue.length > 8) {
            newValue = newValue.slice(0, 8);
        }
        setSelectedBanz(newValue);
    };

    const handleResetClick= (e) => {
        e.preventDefault();
        setSelectedSolar(-1);
        setSelectedBanz(-1);
    }

    const handleUpdateDiscountClick = (e) => {
        e.preventDefault()
        if (grid) {
            /** Get the selected row indexes */
            const selectedrowindex = grid.getSelectedRowIndexes();
            /** Get the selected records. */
            const selectedrecords = grid.getSelectedRecords();
            //     alert(selectedrowindex + " : " + JSON.stringify(selectedrecords));

            //dispatch(login(mobile, password))     
            if (selectedrecords.length > 0) {              
                const arrayOfnum = selectedrecords.map(obj => obj.card_sn ); //
                const commaSeparatedNum = arrayOfnum.join(',');                               
                console.log(`selectedSolar=${selectedSolar} selectedBanz=${selectedBanz} commaSeparatedNum=${commaSeparatedNum}`);                
                updateDiscount(commaSeparatedNum);
                loadData();
            } else {
                alert(t('Select One Client At Least'));
            }
            
        }



    }

    const filterOptions = {
        ignoreAccent: true,
        type: 'Excel'
    };

    const spanStyle = {
        padding: '0px',
        minWidth: '96px',
        textAlign: 'left'
    }

    const inputStyle = {
        border: 'none',
        outline: 'none', // Remove the blue outline on focus
        borderBottom: '1px solid #ccc', // Add a bottom border for a subtle separator
        width: '60px',
        padding: '1px', // Adjust padding as needed 
        textAlign: 'right',
        color: 'blue',
        fontWeight: 'bold',
        height: '30px'
    };

    const settings = { checkboxMode: 'ResetOnRowClick' };

    return (

        <div className="viewcontainer">
            <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Card List')}</h1>
            <div className="cretria grid3container pt-2 pb-5 ps-2">
                <div className="grid4containerBB" >
                    <span style={spanStyle}>{t('solar discount')}:</span><input type="number" min="-1" max="100" step="0.1" name='solar' onBlur={handleBlur} onChange={handleChange}
                        style={inputStyle} value={selectedSolar} ></input>
                    <span style={spanStyle}>{t('banz discount')}:</span><input type="number" min="-1" max="100" step="0.1" name='banz' onBlur={handleBlurB} onChange={handleChangeB}
                        style={inputStyle} value={selectedBanz} ></input>
                </div>
                <div className="grid2container">
                    <Button className="m-1" onClick={handleUpdateDiscountClick} variant={variant_main} >
                        {t('Update Selected Discount')}
                    </Button>
                    <Button className="m-1" onClick={handleResetClick} variant={variant_main} >
                        {t('Reset Discount')}
                    </Button>
                </div>
            </div>
            <GridComponent enableRtl ref={g => grid = g} dataSource={data}
                editSettings={editOptions}
                toolbar={toolbarOptions}
                allowPaging={true}
                allowAdding={true}
                allowEditing={true}
                allowFiltering={true}
                allowDeleting={true}
                allowSorting={true}
                selectionSettings={settings}
                filterSettings={filterOptions}
                pageSettings={{ pageSize: 10, pageSizes: true, pageCount: 6 }}
                cellSaved={onCellSaved}
                actionComplete={handleActionComplete}>
                <ColumnsDirective>
                    <ColumnDirective type='checkbox' width='50' />
                    <ColumnDirective field='card_sn' headerText='card_sn' isPrimaryKey={true} visible={false} width='100'></ColumnDirective>
                    <ColumnDirective field='card_num' headerText={t('card_num')} editable={false} validationRules={requireValid} width='120'></ColumnDirective>
                    <ColumnDirective field='card_name' headerText={t('card_name')} width='150' filter={{ operator: 'contains' }} ></ColumnDirective>
                    <ColumnDirective field='card_tel' headerText={t('card_tel')} width='90'></ColumnDirective>
                    <ColumnDirective field='card_discount' headerText={t('card_discount')} editType="numericedit" validationRules={rangeValid} width='90'></ColumnDirective>
                    <ColumnDirective field='card_discount2' headerText={t('card_discount2')} editType="numericedit" validationRules={rangeValid} width='90'></ColumnDirective>
                    <ColumnDirective field='card_export' headerText={t('card_export')} editType="numericedit" validationRules={rangeValid} width='60'></ColumnDirective>
                    <ColumnDirective field='card_note' headerText={t('card_note')} width='170'></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Edit, Toolbar, Page, Sort, Filter]} />
            </GridComponent>

            <ToastContainer className='toast-style' position="top-center"  >
                <Toast onClose={() => setShowToast(false)} bg={toastColor} show={showToast} delay={5000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">FuelPlus</strong>
                    </Toast.Header>
                    <Toast.Body>{toastText}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default CardListScreen;
