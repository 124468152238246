import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomeScreen from './screens/HomeScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import groupListScreen from './screens/groupListScreen';
import groupEditScreen from './screens/groupEditScreen';
import CsvUploadScreen from './screens/CsvUploadScreen';
import ManualAddScreen from './screens/ManualAddScreen';
import PaymentScreen from './screens/paymentScreen';

import BusinessesListScreen from './screens/businessesListScreen';
import ProductListScreen from './screens/productListScreen';
import CardListScreen from './screens/CardListScreen';
import PricesListSrcreen from './screens/pricesListScreen';

import MonthCustsProductScreen from './reports/MonthCustsProductScreen';
import MonthCustsScreen from './reports/MonthCustsScreen';

import MonthCarScreen from './reports/MonthCar';
import MonthCustCarMovsScreen from './reports/MonthCustCarMovs';
import MonthCustCarMovsPrintScreen from './reports/MonthCustCarMovsPrint';

import YetraDetailedByClientScreen from './reports/YetraDetailedByClientScreen';
import ClientsYtraScreen from './reports/ClientsYtraScreen';
import ClientsNoPaymentAfterDateScreen from './reports/ClientsNoPaymentAfterDateScreen';

import DateTimeCheckScreen from './screens/dateTimeCheckScreen';

import { appName, axoisConfig, getDiscountKind, setDiscountKind } from './constants/generalConstants';

function App() {

  axoisConfig.headers.schema = localStorage.getItem(`${appName}_schema`)
  let discount_kind1 = localStorage.getItem(`${appName}_dis_kind`) ? localStorage.getItem(`${appName}_dis_kind`) : 1
// Set discount_kind value
setDiscountKind(discount_kind1);
// const discountKindValue = getDiscountKind();
// console.log(discountKindValue); // Output: 1

  return (
    <Router>
      <Header />
      <main className='py-2'>
        <div className='poster vh-70'>
          <Route path='/login' component={LoginScreen} />
          <Route path='/busines' component={BusinessesListScreen} />
          <Route path='/date' component={DateTimeCheckScreen} />

          <Route path='/admin/import' component={CsvUploadScreen} />
          <Route path='/admin/manual' component={ManualAddScreen} />
          <Route path='/admin/payment' component={PaymentScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/productlist' component={ProductListScreen} />
          <Route path='/admin/cardlist' component={CardListScreen} />
          <Route path='/admin/priceslist' component={PricesListSrcreen} /> 
          <Route path='/reports/month_custs_product' component={MonthCustsProductScreen} />
          <Route path='/reports/month_custs' component={MonthCustsScreen} />
          <Route path='/reports/month_car' component={MonthCarScreen} exact />
          <Route path="/reports/month_car/:query" component={MonthCarScreen} exact />
          <Route path='/reports/month_cust_car_movs' component={MonthCustCarMovsScreen} exact />
          <Route path='/reports/month_cust_car_movs_print' component={MonthCustCarMovsPrintScreen} exact />          
          <Route path='/reports/yetra_detailed_by_client' component={YetraDetailedByClientScreen} exact />
          <Route path='/reports/clients_yetra' component={ClientsYtraScreen} exact />
          <Route path='/reports/clients_no_payment_after_date' component={ClientsNoPaymentAfterDateScreen} exact />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route path='/admin/catslist' component={groupListScreen} exact />
          <Route
            path='/admin/catslist/:pageNumber'
            component={groupListScreen}
            exact
          />
          <Route path='/admin/cats/:id/edit' component={groupEditScreen} />
          <Route path='/' component={HomeScreen} exact />
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 140" fill="currentColor" className="d-block mt-5 text-white">
          <path d="m0 90 120-16c120-16 360-48 600-48s480 32 600 48l120 16v50.023H0Z" opacity=".6"></path>
          <path d="m0 110 120-16c120-16 360-48 600-48s480 32 600 48l120 16v50.023H0Z"></path>
        </svg>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
