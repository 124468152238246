import axios from 'axios'
import {
  GROUP_LIST_REQUEST,
  GROUP_LIST_SUCCESS,
  GROUP_LIST_FAIL,
  GROUP_DETAILS_REQUEST,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_FAIL,
  GROUP_DELETE_REQUEST,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_FAIL,
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_UPDATE_REQUEST,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAIL
} from '../constants/groupConstants'
import { logout } from './userActions'

export const listGroups = (keyword = '', pageNumber = '') => async (dispatch) => {
  try {
    dispatch({ type: GROUP_LIST_REQUEST })

    const { data } = await axios.get(`/api/cats?keyword=${keyword}&pageNumber=${pageNumber}`)

    dispatch({
      type: GROUP_LIST_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: GROUP_LIST_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })

  }
}

export const listGroupsDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: GROUP_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/cats/${id}`)

    dispatch({
      type: GROUP_DETAILS_SUCCESS,
      payload: data
    })

  } catch (error) {
    dispatch({
      type: GROUP_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message
    })

  }
}

export const deleteGroup = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GROUP_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/cats/${id}`, config)

    dispatch({
      type: GROUP_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
    dispatch({
      type: GROUP_DELETE_FAIL,
      payload: message,
    })
  }
}

export const createGroup = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GROUP_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/cats`, {}, config)

    dispatch({
      type: GROUP_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
    dispatch({
      type: GROUP_CREATE_FAIL,
      payload: message,
    })
  }
}

export const updateGroup = (group) => async (dispatch, getState) => {
  try {
    dispatch({
      type: GROUP_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    console.log(group);
    
    const { data } = await axios.put(
      `/api/cats/${group._id}`,
      group,
      config
    )

    dispatch({
      type: GROUP_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: GROUP_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
    dispatch({
      type: GROUP_UPDATE_FAIL,
      payload: message,
    })
  }
}


