import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { BNameReducer, userLoginReducer, userRegisterReducer, userDetailsReducer, userUpdateProfileReducer, userListReducer, userDeleteReducer, userUpdateReducer } from './reducers/userReducers'
import { groupListReducer, groupDetailsReducer, groupDeleteReducer, groupCreateReducer, groupUpdateReducer } from './reducers/groupReducers'
import rootReducer from './reducers/rootReducer';

const reducer = combineReducers({  
    userLogin: userLoginReducer,
    rootReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,    
    groupList: groupListReducer,
    groupDetails: groupDetailsReducer,
    groupDelete: groupDeleteReducer,
    groupCreate: groupCreateReducer,
    groupUpdate: groupUpdateReducer
})

const userInfoFromStorage = localStorage.getItem('FuelPlus_userInfo') ? JSON.parse(localStorage.getItem('FuelPlus_userInfo')) : null
const BNameFromStorage = localStorage.getItem('FuelPlus_schema_name') ? JSON.parse(localStorage.getItem('FuelPlus_schema_name')) : null


const initialState = {   
    userLogin: { userInfo: userInfoFromStorage },     
}


const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware))
)

export default store