import { axoisConfig , getDiscountKind } from '../constants/generalConstants'

export const fileService = {
  submitInputFile
};


function submitInputFile(selectedFile) {

  console.log(`selectedFile=${selectedFile}`);
  if (selectedFile) {
    var formdata = new FormData();
    formdata.append("file", selectedFile, selectedFile.name);
    //formdata.append("token", this.state.user.token);
    let discountKindValue = getDiscountKind();
    let config = { ...axoisConfig }

    var requestOptions = {
      method: 'POST',
      headers: {
        'schema': config.headers.schema
      },
      body: formdata,
      redirect: 'follow'
    };

    return fetch('/api/fuel/upload', requestOptions);

  }

  return new Promise((resolve, reject) => {
    // Simulating an asynchronous operation that takes some time
    reject(new Error('File Not Found'));
  });

}
