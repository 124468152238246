import { useEffect, useState } from 'react';

import { GridComponent, Inject, toolbarClick } from '@syncfusion/ej2-react-grids';
import { ColumnsDirective, ColumnDirective, CommandColumn, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import { Page, Filter, Sort, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import iconv from 'iconv-lite';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { axoisConfig, variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'

import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

//import { getMonthlyCustsSum_ByProduct } from '../services/ordersService';
import axios from 'axios';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXlcc3RTRGNYUEJwWUA=');
const ClientsNoPaymentAfterDateScreen = ({ history }) => {

  const [data, setData] = useState([]);
  //const [selectedDate, setSelectedDate] = useState(null);

  // const [dataCards, setDataCards] = useState([]);
  // const [selectedClient, setSelectedClient] = useState(0);
  //
  const sixMonthbefore = new Date();
  sixMonthbefore.setMonth(sixMonthbefore.getMonth() - 3);

  const [selectedDate1, setSelectedDate1] = useState(sixMonthbefore);
  const [selectedYtra1, setSelectedYtra1] = useState('0');


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {
      loadData();
    } else {
      history.push('/login')
    }
    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

  }, [history]);



  const loadData = async () => {
    try {
      const data = {
        // JSON data to be sent in the request body
        dt1: selectedDate1,
        ytra1: selectedYtra1
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.post('/api/fuel/rpt_clients_no_payment_after_date', data, config);

      const updatedArray = response.data.map(obj => ({
        ...obj,
        yetra_now: parseFloat(obj.yetra_now)
      }));

      setData(updatedArray);

    } catch (error) {
      console.log(error);
    }
  };




  function getTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}`;
  }

  const dataSrcs = [
    { name: " הכל", value: "" },
    { name: "רוזמן", value: "rsm" },
    { name: "פנקסים", value: "manual" },
  ];
  // Function to convert a string to ANSI encoding (windows-1255)
  const stringToANSI = (str) => {
    const buffer = iconv.encode(str, 'windows-1255');
    return new Uint8Array(buffer);
  };

  const handleBlur = () => {
    // Perform any validation or processing here before updating the state
    const numericValue = parseFloat(selectedYtra1); // Convert the value to a numeric format
    if (!isNaN(numericValue)) {
      setSelectedYtra1(numericValue); // Round the numeric value to 2 decimal places
    } else  setSelectedYtra1(0);
  };

  const handleChange = (event) => {
    // Allow only numeric values and a dot (for decimals)
    let newValue = event.target.value.replace(/[^0-9.]/g, '');

    if (newValue.length == 0) newValue=0;
    if (newValue.length > 8) {
      newValue = newValue.slice(0, 8);
    }
    setSelectedYtra1(newValue);
  };

  const inputStyle = {
    border: 'none',
    outline: 'none', // Remove the blue outline on focus
    borderBottom: '1px solid #ccc', // Add a bottom border for a subtle separator
    width: '100%',
    padding: '5px', // Adjust padding as needed 
    textAlign: 'right'
  };


  function footerSum(props) {
    return (<span>{t('Sum')}: {props.Sum}</span>);
  }

  let gridInstance;
  let flag = true;

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true
  }
  const toolbarOptions = ['ExcelExport']

  const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
  { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
  { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];


  let mygrid = null;

  const selectionOptions = {
    checkboxMode: 'ResetOnRowClick',
    enableToggle: true
  }


  const { t } = useTranslation();

  const exportProperties = {
    fileName: 'invoice.pdf',
    exportType: 'CurrentPage'
  };
  const toolbarClick = (args) => {
    if (gridInstance && args.item.id.includes('pdfexport')) {
      gridInstance.pdfExport(exportProperties);
    } else if (gridInstance && args.item.id.includes('excelexport')) {
      gridInstance.excelExport();
    }
  }


  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    console.log(`Date1=${selectedDate1}`);
    console.log(`selectedYtra1=${selectedYtra1}`);
    //console.log(`selectedClient=${selectedClient}`);    
    // refreshGrid(userInfo);
    if (selectedYtra1) {
      loadData();
    }
  }


  const handleDate1Change = (args) => {
    setSelectedDate1(args.value);
  };


  function formatDateToDDMMYYYY(date1) {
    const date= new Date(date1);
    const dd = String(date.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-based) and add 1, then pad with 0 if needed
    const yyyy = date.getFullYear(); // Get the year
  
    return `${dd}/${mm}/${yyyy}`;
  }

  const excelQueryCellInfo = (args) => {
    if (args.column.field === 'dt_from') {
      args.value = formatDateToDDMMYYYY(args.data.dt_from);
    }

  };

  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Rpt Clients No Payment After Date')}</h1>
      <div className="cretria grid3container pt-2 pb-5 ps-2">

        <div className="grid2container">
          <DatePickerComponent format="dd/MM/yyyy"
            enableRtl
            value={selectedDate1}
            change={handleDate1Change}
          ></DatePickerComponent>
          <input type='number' name='ytra1' onBlur={handleBlur} onChange={handleChange}
            placeholder={t('Enter a Ytra')} style={inputStyle} value={selectedYtra1} ></input>
        </div>


        <div className="grid3container">
          <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
            {t('Search')}
          </Button>
        </div>
      </div>
      <GridComponent className="mx-0" enableRtl ref={grid => gridInstance = grid} id="mygrid" name="mygrid" dataSource={data}
        allowPaging={true}
        pageSettings={{ pageSize: 25, pageSizes: true, pageCount: 4 }}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick.bind(this)}
        excelQueryCellInfo={excelQueryCellInfo}
        allowSorting={true}
        allowExcelExport={true}
      /// dataStateChange={dataStateChange.bind(this)}
      // frozenRows={0} frozenColumns={1}
      //dataSourceChanged={dataSourceChanged}
      >
        <ColumnsDirective>
          {/* <ColumnDirective field='gender' headerText='Gender' textAlign='Right' width='100' />
          <ColumnDirective field='email' headerText='Email' width='150' />
          <ColumnDirective field='nat' headerText='Nationality' /> */}
          <ColumnDirective field='card_num' headerText={t('card_num')} textAlign='Right' width='80' isPrimaryKey={true} />
          <ColumnDirective field='card_name' headerText={t('card_name')} textAlign='Right' width='200' />
          <ColumnDirective field='dt_from' headerText={t('pay_dt_from')} type="date" format="dd/MM/yyy" width='100' />
          <ColumnDirective field='yetra_now' headerText={t('yetra_now')} type="number" format="N2" width='80' />
        </ColumnsDirective>
        <AggregatesDirective>
          <AggregateDirective>
            <AggregateColumnsDirective>
              <AggregateColumnDirective field='yetra_now' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
            </AggregateColumnsDirective>
          </AggregateDirective>

        </AggregatesDirective>
        <Inject services={[Page, Sort, Toolbar, ExcelExport, Aggregate]} />

      </GridComponent>
    </div>

  )
}

export default ClientsNoPaymentAfterDateScreen
