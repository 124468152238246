import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import './bootstrap_theme/bootstrap.css';
import './bootstrap.rtl.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// import i18n (needs to be bundled ;)) 
import './i18n';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </Provider>);

// ReactDOM.render(
//   <Provider store={store}>
//     <I18nextProvider i18n={i18next}>
//       <App />
//     </I18nextProvider>
//   </Provider>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
