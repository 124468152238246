import { useEffect, useRef, useState } from 'react';

import { GridComponent, Inject, toolbarClick } from '@syncfusion/ej2-react-grids';
import { ColumnsDirective, ColumnDirective, CommandColumn, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import {
    Page, Filter, Sort, Edit, Freeze, Resize, Reorder, Scroll,
    Toolbar, ExcelExport, PdfExport, Grid
} from '@syncfusion/ej2-react-grids';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { axoisConfig, variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next';

//import { getMonthlyCustsSum_ByProduct } from '../services/ordersService';
import axios from 'axios';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXlcc3RTRGNYUEJwWUA=');
const DateTimeCheckScreen = ({ history }) => {

    const [data, setData] = useState([]);

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() == 0 ? 12 : new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(selectedMonth == 12 ? new Date().getFullYear() - 1 : new Date().getFullYear());
    const [selectedDate2, setSelectedDate2] = useState(new Date());
    const [selectedtime, setSelectedTime] = useState(new Date());



    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin


    useEffect(() => {
        if (userInfo && userInfo.usr_role === 'admin') {
            loadData();
        } else {
            history.push('/login')
        }
        // Example usage
        const element = findElementWithText("To remove the license validation");
        if (element) {
            console.log("Element found:", element);
            element.style.display = 'none';
            element.parentNode.removeChild(element);
        } else {
            console.log("Element not found.");
        }

    }, [history]);


    const loadData = async () => {
        try {


            let config = { ...axoisConfig }
            config.headers.Authorization = `${userInfo.token}`;

            const response = await axios.get('/api/fuel/store_datetime', config);

            setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };


    const insertData = async () => {
        try {
             const hours = selectedtime.getHours();
             const minutes = selectedtime.getMinutes();
             const seconds = selectedtime.getSeconds();

            // Set the time part to the selectedDate
            selectedDate2.setHours(hours);
            selectedDate2.setMinutes(minutes);
            selectedDate2.setSeconds(seconds);

            let data1 = {
                // JSON data to be sent in the request body
                datetime: selectedDate2
                // Add more key-value pairs as needed
            };
            console.log(data1);
            let config = { ...axoisConfig }
            config.headers.Authorization = `${userInfo.token}`;

            const response = await axios.post('/api/fuel/store_datetime', data1, config);

            //setData(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    function footerSum(props) {
        return (<span>{t('Sum')}: {props.Sum}</span>);
    }

    let gridInstance;
    let flag = true;

    const editOptions = {
        allowEditing: true, allowAdding: true, allowDeleting: true
    }
    const toolbarOptions = ['ExcelExport']

    const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
    { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
    { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
    { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];


    let mygrid = null;

    const selectionOptions = {
        checkboxMode: 'ResetOnRowClick',
        enableToggle: true
    }

    const { t } = useTranslation();

    const exportProperties = {
        fileName: 'invoice.pdf',
        exportType: 'CurrentPage'
    };
    const toolbarClick = (args) => {
        if (gridInstance && args.item.id.includes('pdfexport')) {
            gridInstance.pdfExport(exportProperties);
        } else if (gridInstance && args.item.id.includes('excelexport')) {
            gridInstance.excelExport();
        }
    }

    //const products = ['סולר', 'בנזין 95', 'אוריה', 'שתיה', 'סגריות'];

    // Search for an element containing the specified text
    function findElementWithText(text) {

        // return document.querySelector(`span:contains(${text})`);

        const elements = document.querySelectorAll('span'); // Select all elements in the document

        for (let i = 0; i < elements.length; i++) {
            if (elements[i].textContent.includes(text)) {
                return elements[i].parentElement;
            }
        }

        return null; // Return null if no matching element is found
    }

    const handleSearchClick = (e) => {
        e.preventDefault()
        //dispatch(login(mobile, password))

        console.log(`Year=${selectedYear} Month=${selectedMonth}`);
        //console.log(`selectedProducts=${selectedProducts}`);
        //console.log(`selectedClients=${selectedClients}`);
        // refreshGrid(userInfo);
        loadData();
    }

    const handleInsertClick = (e) => {
        e.preventDefault()
        //dispatch(login(mobile, password))

        insertData();
    }

    const handleDateChange = (args) => {
        setSelectedYear(args.value?.getFullYear());
        setSelectedMonth(args.value?.getMonth() + 1);
    };

    const handleDate2Change = (args) => {
        setSelectedDate2(args.value);
    };

    const handleTimeChange = (args) => {
        setSelectedTime(args.value);
    };


    return (
        <div className="viewcontainer">
            <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Date Time Check')}</h1>
            <div className="cretria grid3container pt-2 pb-5 ps-2">



                <div className="grid2container">
                    <DatePickerComponent format="dd/MM/yyyy"
                        enableRtl
                        value={selectedDate2}
                        change={handleDate2Change}
                    ></DatePickerComponent>
                    <TimePickerComponent
                        enableRtl
                        id="timepicker" value={selectedtime} step={1} format={'HH:mm'} onChange={handleTimeChange} />

                </div>

                <div className="grid2container">
                    <Button className="m-1" onClick={handleInsertClick} variant={variant_main} >
                        {t('Insert')}
                    </Button>
                    <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
                        {t('Search')}
                    </Button>
                </div>
            </div>
            <GridComponent className="mx-0" enableRtl ref={grid => gridInstance = grid} id="mygrid" name="mygrid" dataSource={data}
                allowPaging={true}
                pageSettings={{ pageSize: 10, pageSizes: true, pageCount: 4 }}
                toolbar={toolbarOptions}
                toolbarClick={toolbarClick.bind(this)}
                allowSorting={true}
                allowExcelExport={true}
            /// dataStateChange={dataStateChange.bind(this)}
            // frozenRows={0} frozenColumns={1}
            //dataSourceChanged={dataSourceChanged}
            >
                <ColumnsDirective>
                    {/* <ColumnDirective field='gender' headerText='Gender' textAlign='Right' width='100' />
          <ColumnDirective field='email' headerText='Email' width='150' />
          <ColumnDirective field='nat' headerText='Nationality' /> */}

                    <ColumnDirective field='sn' headerText={t('sn')} textAlign='Right' width='80' isPrimaryKey={true} />
                    <ColumnDirective field='dt_auto' headerText={t('dt_auto')} format="dd/MM/yyy HH:mm" type="datetime" textAlign='right' width='100' />
                    <ColumnDirective field='dt_utc_in' headerText={t('dt_utc_in')} format="dd/MM/yyy HH:mm" type="datetime" textAlign='right' width='100' />
                    <ColumnDirective field='dt_utc_local' headerText={t('dt_utc_local')} format="dd/MM/yyy HH:mm" type="datetime" textAlign='right' width='100' />
                    <ColumnDirective field='dt_utc_server' headerText={t('dt_utc_server')} format="dd/MM/yyy HH:mm" type="datetime" textAlign='right' width='100' />

                </ColumnsDirective>

                <Inject services={[Page, Sort, Toolbar]} />
            </GridComponent>
        </div>

    )
}

export default DateTimeCheckScreen
