import React, { useEffect, useState, useRef } from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Edit, Toolbar, Page, Sort, Filter } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { AutoComplete } from '@syncfusion/ej2-dropdowns';
import { axoisConfig, variant_main } from '../constants/generalConstants';


import axios from 'axios';
import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useTranslation } from 'react-i18next';

registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');

const PricesListSrcreen = ({ history }) => {
    const [data, setData] = useState([]);
    const [dataProducts, setDataProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(0);
    const [showToast, setShowToast] = useState(false);
    const [toastText, setToastText] = useState('');
    const [toastColor, setToastColor] = useState('');

    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


    const gridRef = useRef(null);

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const loadData = async () => {
        try {

            const data = {
                // JSON data to be sent in the request body
                m1: selectedMonth + 1,
                y1: selectedYear,
                product1: selectedProduct
                // Add more key-value pairs as needed
            };

            let config = { ...axoisConfig }
            config.headers.Authorization = `${userInfo.token}`;

            const Response = await axios.post('/api/fuel/prices', data, config);
            setData(Response.data);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const loadDataProducts = async () => {
        try {

            let config = { ...axoisConfig }
            config.headers.Authorization = `${userInfo.token}`;

            const response = await axios.get('/api/fuel/products', config);
            let data1 = response.data
            const newElement = { prd_sn: 0, prd_name: `${t('ALL')}` };
            data1.unshift(newElement);
            //data1.push(newElement);

            setDataProducts(data1);

        } catch (error) {
            console.log(error);
        }
    };

    function findElementWithText(text) {

        // return document.querySelector(`span:contains(${text})`);
    
        const elements = document.querySelectorAll('span'); // Select all elements in the document
    
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].textContent.includes(text)) {
            return elements[i].parentElement;
          }
        }
    
        return null; // Return null if no matching element is found
      }

    useEffect(() => {
        if (userInfo && userInfo.usr_role === 'admin') {
            loadDataProducts();
            loadData();

            // Example usage
            const element = findElementWithText("To remove the license validation");
            if (element) {
                console.log("Element found:", element);
                element.style.display = 'none';
                element.parentNode.removeChild(element);
            } else {
                console.log("Element not found.");
            }
        } else {
            history.push('/login')
        }
        // Example usage
        // const element = findElementWithText("To remove the license validation");
        // if (element) {
        //     console.log("Element found:", element);
        //     element.style.display = 'none';
        //     element.parentNode.removeChild(element);
        // } else {
        //     console.log("Element not found.");
        // }
    }, [history]);

    const editOptions = {
        allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' //, showConfirmDialog: false
    }

    const toolbarOptions = ['Add', 'Edit', 'Update', 'Cancel'];
    const rangeValid = { range: [0, 100] };
    const requireValid = { required: true };

    const handleSearchClick = (e) => {
        e.preventDefault()
        //dispatch(login(mobile, password))

        console.log(`Year=${selectedYear} Month=${selectedMonth + 1}`);
        console.log(`selectedProduct=${selectedProduct}`);

        loadData();
    }
    //let array=[];
    const onCellSaved = (args) => {
        //    console.log(args);
        //   array.push(parseInt(args.cell.getAttribute('aria-colindex')) + 'of' + parseInt(args.cell.getAttribute('index'))) 
    };

    const handleShowToast = (text, color) => {
        setToastText(text);
        setToastColor(color);
        setShowToast(true);
    };

    const handleActionComplete = (args) => {

        let config = { ...axoisConfig }
        config.headers.Authorization = `${userInfo.token}`;

        if (args.requestType === 'save') {
            let dt1=new Date(args.data.prc_date);
            dt1.setHours(dt1.getHours() + 10);
            args.data.prc_date=dt1;    
            // Add new data when the "Add" operation is completed
            if (args.action === 'add') {
                axios.post('/api/fuel/pricelist/', args.data, config)
                    .then((response) => {
                        setData(prevData => [...prevData, response.data]);
                        handleShowToast(t('Added Succesfully'), 'success');

                    })
                    .catch((error) => {
                        console.error('Error adding data:', error);
                        handleShowToast(t(error.response.data), 'danger');
                        //Card already registered
                    });
            }
            // Update existing data when the "Edit" operation is completed
            else if (args.action === 'edit') {
                console.log(args.data);
                axios.put(`/api/fuel/pricelist/${args.data.prc_sn}`, args.data, config)
                    .catch((error) => {
                        console.error('Error updating data:', error);
                    });
            }
            
            //""            
        } else if (args.requestType === 'batchsave') {
            // console.log(args);
            // const modifiedData = gridRef.current.getBatchChanges();
            // console.log(modifiedData);
            // // axios.post(`/api/cards/batch`, args.data, config)
            //     .catch((error) => {
            //         console.error('Error updating data:', error);
            //     });
        }
    };

    const handleDateChange = (args) => {
        setSelectedYear(args.value?.getFullYear());
        setSelectedMonth(args.value?.getMonth());
    };

    const handleProductValueChange = (args) => {
        const newSelectedItems = args.value; // Array of selected items
        setSelectedProduct(newSelectedItems);
    };

    const createProductFn = () => {
        inpuEleProduct = document.createElement('input');
        return inpuEleProduct;
    };
    const destroyProductFn = () => {
        autoCompleteInsProduct.destroy();
    };
    const readProductFn = () => {
        return autoCompleteInsProduct.value;
    };
    const writeProductFn = async (args) => {

        try {

            let config = { ...axoisConfig }
            config.headers.Authorization = `${userInfo.token}`;

            const productsResponse = await axios.get('/api/products', config);

            autoCompleteInsProduct = new AutoComplete({
                allowCustom: false,
                value: args.rowData[args.column.field],
                dataSource: productsResponse.data,
                fields: { value: 'prd_name', text: 'prd_name' },
            });
            autoCompleteInsProduct.appendTo(args.element);
        } catch (error) {
            console.error("Error fetching Products data:", error);
        }
    };

    let inpuEleProduct;
    let autoCompleteInsProduct;

    const daPrParams = {
        create: createProductFn,
        destroy: destroyProductFn,
        read: readProductFn,
        write: writeProductFn
    };

    const { t } = useTranslation();
    const NumericRequiredValidator = { required: true, number: true };
    const RequiredValidator = { required: true, minLength: 2 };

    return (

        <div className="viewcontainer">
            <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Price List')}</h1>

            <div className="cretria grid3container pt-2 pb-5 ps-2">

                <DropDownListComponent id="atcelement"
                    enableRtl
                    placeholder={t('Select Product')}
                    dataSource={dataProducts}
                    fields={{ value: "prd_sn", text: "prd_name" }}
                    change={handleProductValueChange}
                ></DropDownListComponent>

                <DatePickerComponent start="Year" format="MM/yyyy" depth="Year"
                    enableRtl
                    value={new Date(selectedYear, selectedMonth, 1)}
                    change={handleDateChange}
                ></DatePickerComponent>
                <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
                    {t('Search')}
                </Button>
            </div>


            <GridComponent enableRtl ref={gridRef} dataSource={data}
                editSettings={editOptions}
                toolbar={toolbarOptions}
                allowPaging={true}
                allowAdding={true}
                allowEditing={true}
                allowFiltering={true}
                allowDeleting={true}
                allowSorting={true}
                pageSettings={{ pageSize: 10, pageSizes: true, pageCount: 6 }}
                actionComplete={handleActionComplete}>
                <ColumnsDirective>
                    <ColumnDirective field='prc_sn' headerText='prc_sn' isPrimaryKey={true} visible={false} width='100'></ColumnDirective>
                    <ColumnDirective field="prc_date" headerText={t('prc_date')} width="120" textAlign="Right" type="datetime" format="dd/MM/yyy" editType="datepickeredit" />
                    <ColumnDirective field="prd_name" headerText={t('prd_name')} editType='dropdownedit' edit={daPrParams} width="180" textAlign="Right" validationRules={RequiredValidator} />
                    <ColumnDirective field='prc_price' headerText={t('prc_price')} editType="numericedit" validationRules={rangeValid} width='90'></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Edit, Toolbar, Page, Sort, Filter]} />
            </GridComponent>

            <ToastContainer className='toast-style' position="top-center"  >
                <Toast onClose={() => setShowToast(false)} bg={toastColor} show={showToast} delay={5000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">FuelPlus</strong>
                    </Toast.Header>
                    <Toast.Body>{toastText}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
};

export default PricesListSrcreen;
