import { useEffect, useState } from 'react';

import { GridComponent, Inject, toolbarClick } from '@syncfusion/ej2-react-grids';
import { ColumnsDirective, ColumnDirective, CommandColumn, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import { Page, Filter, Sort, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import iconv from 'iconv-lite';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { Query } from '@syncfusion/ej2-data';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { axoisConfig, variant_main } from '../constants/generalConstants'

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'

import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

//import { getMonthlyCustsSum_ByProduct } from '../services/ordersService';
import axios from 'axios';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXlcc3RTRGNYUEJwWUA=');
const YetraDetailedByClientScreen = ({ history }) => {

  const [data, setData] = useState([]);
  //const [selectedDate, setSelectedDate] = useState(null);

  const [dataCards, setDataCards] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [selectedClientNum, setSelectedClientNum] = useState(0); //setSelectedClient

  const oneMonthLater = new Date();
  oneMonthLater.setMonth(oneMonthLater.getMonth() - 1);
  oneMonthLater.setDate(1);
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [selectedDate1, setSelectedDate1] = useState(oneMonthLater);


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {
      loadDataCards();
      loadData();
    } else {
      history.push('/login')
    }
    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

  }, [history]);


  const loadDataCards = async () => {
    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/fuel/clients', config);
      let data1 = response.data
      const newElement = { card_num: '0', card_name: `${t('Select Client')}` };
      data1.unshift(newElement);
      //data1.push(newElement);

      setDataCards(data1);

    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async () => {
    try {
      const data = {
        // JSON data to be sent in the request body
        dt1: selectedDate1,
        dt2: selectedDate2,
        cardnum1: selectedClientNum
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.post('/api/fuel/rpt_yetra_detailed_by_client', data, config);

      const updatedArray = response.data.map(obj => ({
        ...obj,
        pay_date: obj.pay_date.split(':')[0]
        
      }));

      setData(updatedArray);
    //      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  function getTimestamp() {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}${month}${day}_${hours}${minutes}`;
  }

  const dataSrcs = [
    { name: " הכל", value: "" },
    { name: "רוזמן", value: "rsm" },
    { name: "פנקסים", value: "manual" },
  ];
  // Function to convert a string to ANSI encoding (windows-1255)
  const stringToANSI = (str) => {
    const buffer = iconv.encode(str, 'windows-1255');
    return new Uint8Array(buffer);
  };



  function footerSum(props) {
    return (<span>{t('Sum')}: {props.Sum}</span>);
  }

  let gridInstance;
  let flag = true;

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true
  }
  const toolbarOptions = ['ExcelExport']

  const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
  { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
  { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];


  let mygrid = null;

  const selectionOptions = {
    checkboxMode: 'ResetOnRowClick',
    enableToggle: true
  }


  const { t } = useTranslation();

  const exportProperties = {
    fileName: 'invoice.pdf',
    exportType: 'CurrentPage'
  };
  const toolbarClick = (args) => {
    if (gridInstance && args.item.id.includes('pdfexport')) {
      gridInstance.pdfExport(exportProperties);
    } else if (gridInstance && args.item.id.includes('excelexport')) {
      gridInstance.excelExport();
    }
  }

  //const products = ['סולר', 'בנזין 95', 'אוריה', 'שתיה', 'סגריות'];

  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    console.log(`Date1=${selectedDate1} Date2=${selectedDate2}`);
    //console.log(`selectedProducts=${selectedProducts}`);
    console.log(`selectedClient=${selectedClient} selectedClientNum=${selectedClientNum}`);
    // refreshGrid(userInfo);
    loadData();
  }


  const handleDate1Change = (args) => {
    setSelectedDate1(args.value);
  };

  const handleDate2Change = (args) => {
    setSelectedDate2(args.value);
  };

  const handleClientValueChange = (args) => {
    const newSelectedItems = args.value; // Array of selected items
    setSelectedClient(newSelectedItems);
    const targetCard = dataCards.find(item => item.card_name === newSelectedItems);

    if (targetCard) {
          setSelectedClientNum(targetCard.card_num);
    }else setSelectedClientNum(0);
  };

  function periodTemp(props) {
    return (<span>{t(props.descr1)}</span>);
  }

  const excelQueryCellInfo = (args) => {
    if (args.column.field === 'descr1') {
      args.value = t(args.data.descr1);
    }

  };

  const query = new Query().from('cards').select(["card_name", "card_num"]).take(10);

  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Rpt Yetra Detailed By Client')}</h1>
      <div className="cretria grid3container pt-2 pb-5 ps-2">

        <div className="grid2container">
          <DatePickerComponent format="dd/MM/yyyy"
            enableRtl
            value={selectedDate1}
            change={handleDate1Change}
          ></DatePickerComponent>
          <DatePickerComponent format="dd/MM/yyyy"
            enableRtl
            value={selectedDate2}
            change={handleDate2Change}
          ></DatePickerComponent>
        </div>
        <div className="grid2container">
          {/* <DropDownListComponent id="atcelement"
            enableRtl
            placeholder={t('Select Client')}
            dataSource={dataCards}
            value={selectedClient}
            fields={{ value: "card_num", text: "card_name" }}
            change={handleClientValueChange}
          ></DropDownListComponent> */}
          <AutoCompleteComponent id="atcelement"
            enableRtl
            placeholder={t('Select Client')}
            dataSource={dataCards}           
            value={selectedClient}            
            highlight={true}
            change={handleClientValueChange}
            fields={{ value: "card_name", text: "card_name" }}
          ></AutoCompleteComponent>
        </div>


        <div className="grid3container">
          <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
            {t('Search')}
          </Button>
        </div>
      </div>
      <GridComponent className="mx-0" enableRtl ref={grid => gridInstance = grid} id="mygrid" name="mygrid" dataSource={data}
        allowPaging={true}
        pageSettings={{ pageSize: 10, pageSizes: true, pageCount: 4 }}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick.bind(this)}
        excelQueryCellInfo={excelQueryCellInfo}
        allowSorting={true}
        allowExcelExport={true}
      /// dataStateChange={dataStateChange.bind(this)}
      // frozenRows={0} frozenColumns={1}
      //dataSourceChanged={dataSourceChanged}
      >
        <ColumnsDirective>
          {/* <ColumnDirective field='gender' headerText='Gender' textAlign='Right' width='100' />
          <ColumnDirective field='email' headerText='Email' width='150' />
          <ColumnDirective field='nat' headerText='Nationality' /> */}
          <ColumnDirective field='rsm_card_num' headerText={t('rsm_card_num')} textAlign='Right' width='60' isPrimaryKey={true} />
          <ColumnDirective field='rsm_card_name' headerText={t('rsm_card_name')} textAlign='Right' width='130' />
          <ColumnDirective field='card_tel' headerText={t('card_tel')} textAlign='Right' width='70' />
          <ColumnDirective field='descr1' headerText={t('descr1')} template={periodTemp} width='100' />
          <ColumnDirective field='period1' headerText={t('period1')} textAlign='right' enableRtl='false' width='100' />
          <ColumnDirective field='pay_date' headerText={t('pay_date')} textAlign='right' enableRtl='false' width='75' />
          <ColumnDirective field='month1' headerText={t('month1')} textAlign='right' enableRtl='false' width='60' />
          <ColumnDirective field='prod1' headerText={t('prod1')} width='80' />
          <ColumnDirective field='hova' headerText={t('hova')} width='80' />
          <ColumnDirective field='zchot' headerText={t('zchot')} width='80' />
        </ColumnsDirective>

        <Inject services={[Page, Sort, Toolbar, ExcelExport]} />

      </GridComponent>
    </div>


  )

}

export default YetraDetailedByClientScreen
