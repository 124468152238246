import { useEffect, useRef, useState } from 'react';

import { GridComponent, Inject, toolbarClick } from '@syncfusion/ej2-react-grids';
import { ColumnsDirective, ColumnDirective, CommandColumn, Aggregate, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import {
  Page, Filter, Sort, Edit, Freeze, Resize, Reorder, Scroll,
  Toolbar, ExcelExport, PdfExport, Grid
} from '@syncfusion/ej2-react-grids';
import { DropDownListComponent, AutoCompleteComponent } from '@syncfusion/ej2-react-dropdowns';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { axoisConfig, variant_main } from '../constants/generalConstants';

import '../App.css';
//import data from './datasource.json';

import { registerLicense } from '@syncfusion/ej2-base';
import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Messages'
import Loader from '../components/Loader'
import { useTranslation } from 'react-i18next';

//import { getMonthlyCustsSum_ByProduct } from '../services/ordersService';
import axios from 'axios';


registerLicense('ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5WdkxjUXxWcnxdRGlb');
//registerLicense('Ngo9BigBOggjHTQxAR8/V1NGaF5cXmdCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXlcc3RTRGNYUEJwWUA=');
const MonthCustCarMovsScreen = ({ history }) => {

  const [data, setData] = useState([]);
  const [dataCards, setDataCards] = useState([]);

  const [selectedClient, setSelectedClient] = useState('');
  const [selectedClientNum, setSelectedClientNum] = useState(0); //setSelectedClient

  const [selectedSrc, setSelectedSrc] = useState('');


  const oneMonthLater = new Date();
  oneMonthLater.setMonth(oneMonthLater.getMonth() - 1);
  oneMonthLater.setDate(1);
  const [selectedDate2, setSelectedDate2] = useState(new Date());
  const [selectedDate1, setSelectedDate1] = useState(oneMonthLater);

  const [selectedSolar, setSelectedSolar] = useState(0);
  const [selectedBanz, setSelectedBanz] = useState(0);
  //

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin


  useEffect(() => {
    if (userInfo && userInfo.usr_role === 'admin') {
      loadDataCards();
      loadData();
    } else {
      history.push('/login')
    }
    // Example usage
    const element = findElementWithText("To remove the license validation");
    if (element) {
      console.log("Element found:", element);
      element.style.display = 'none';
      element.parentNode.removeChild(element);
    } else {
      console.log("Element not found.");
    }

  }, [history]);

  const dataSrcs = [
    { name: " הכל", value: "" },
    { name: "רוזמן", value: "rsm" },
    { name: "פנקסים", value: "manual" },
  ];

  const loadData = async () => {
    try {
      const data = {
        // JSON data to be sent in the request body
        dt1: selectedDate1,
        dt2: selectedDate2,
        cardnum1: selectedClientNum,
        src1: selectedSrc
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.post('/api/fuel/rpt_month_cust_car_movs', data, config);

      const updatedArray = response.data.map(obj => ({
        ...obj,
        rsm_total: parseFloat(obj.rsm_total),
        rsm_total_final: parseFloat(obj.rsm_total_final),
        rsm_qnt: parseFloat(obj.rsm_qnt),
        rsm_price: parseFloat(obj.rsm_price)
      }));

      setData(updatedArray);
    } catch (error) {
      console.log(error);
    }
  };


  const loadDataCards = async () => {
    try {

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.get('/api/fuel/clients', config);
      setDataCards(response.data);

    } catch (error) {
      console.log(error);
    }
  };

  //updateDiscount
  const updateDiscount = async () => {
    try {
      const data = {
        // JSON data to be sent in the request body
        dt1: selectedDate1,
        dt2: selectedDate2,
        cardnum1: selectedClientNum,
        src1: selectedSrc,
        discount1: selectedSolar,
        discount2: selectedBanz
        // Add more key-value pairs as needed
      };

      let config = { ...axoisConfig }
      config.headers.Authorization = `${userInfo.token}`;

      const response = await axios.post('/api/fuel/update_discount_movs', data, config);
      console.log(response);

    } catch (error) {
      console.log(error);
    }
  };

  function footerSum(props) {
    return (<span>{props.Sum}</span>);
  }

  let gridInstance;
  let flag = true;

  const editOptions = {
    allowEditing: true, allowAdding: true, allowDeleting: true
  }
  const toolbarOptions = ['ExcelExport']

  const commands = [{ type: 'Edit', buttonOption: { iconCss: ' e-icons e-edit', cssClass: 'e-flat' } },
  { type: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
  { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
  { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } }];


  let mygrid = null;

  const selectionOptions = {
    checkboxMode: 'ResetOnRowClick',
    enableToggle: true
  }

  const btnClick = (event) => {
    //event.preventDefault();
    if (mygrid) {
      var selectedRecords = mygrid.getSelectedRecords();
      console.log(selectedRecords);
    }
  }

  const spanStyle = {
    padding: '0px',
    minWidth: '96px',
    textAlign: 'left'
  }

  const inputStyle = {
    border: 'none',
    outline: 'none', // Remove the blue outline on focus
    borderBottom: '1px solid #ccc', // Add a bottom border for a subtle separator
    width: '60px',
    padding: '1px', // Adjust padding as needed 
    textAlign: 'right',
    color: 'blue',
    fontWeight: 'bold',
    height: '30px'
  };

  const { t } = useTranslation();

  const exportProperties = {
    fileName: 'invoice.pdf',
    exportType: 'CurrentPage'
  };
  const toolbarClick = (args) => {
    if (gridInstance && args.item.id.includes('pdfexport')) {
      gridInstance.pdfExport(exportProperties);
    } else if (gridInstance && args.item.id.includes('excelexport')) {
      gridInstance.excelExport();
    }
  }


  // Search for an element containing the specified text
  function findElementWithText(text) {

    // return document.querySelector(`span:contains(${text})`);

    const elements = document.querySelectorAll('span'); // Select all elements in the document

    for (let i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(text)) {
        return elements[i].parentElement;
      }
    }

    return null; // Return null if no matching element is found
  }

  const handleSearchClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))

    console.log(`Date1=${selectedDate1} Date2=${selectedDate2}`);
    console.log(`selectedSrc=${selectedSrc}`);
    console.log(`selectedClient=${selectedClient} setSelectedClientNum=${selectedClientNum}`);

    // refreshGrid(userInfo);
    loadData();
  }


  const handleUpdateDiscountClick = (e) => {
    e.preventDefault()
    //dispatch(login(mobile, password))
    if (selectedClientNum > 0) {
      console.log(`selectedSolar=${selectedSolar} selectedBanz=${selectedBanz}`);
      console.log(`Date1=${selectedDate1} Date2=${selectedDate2}`);
      console.log(`selectedSrc=${selectedSrc}`);
      console.log(`setSelectedClientNum=${selectedClientNum}`);

      updateDiscount();
      loadData();
    } else alert(t('Select Client'));
  }

  function discountTemp(props) {
    return (<span><b>{props.card_discount}</b></span>);
  }

  const handleDate1Change = (args) => {
    setSelectedDate1(args.value);
  };

  const handleDate2Change = (args) => {
    setSelectedDate2(args.value);
  };

  const handleClientValueChange = (args) => {
    const newSelectedItems = args.value; // Array of selected items
    setSelectedClient(newSelectedItems);
    const targetCard = dataCards.find(item => item.card_name === newSelectedItems);

    if (targetCard) {
      setSelectedClientNum(targetCard.card_num);
      setSelectedSolar(targetCard.card_discount);
      setSelectedBanz(targetCard.card_discount2);
    } else setSelectedClientNum(0);
  };


  const handleSrcValueChange = (args) => {
    const newSelectedItems = args.value; // Array of selected items
    setSelectedSrc(newSelectedItems);
  };

  const handleBlur = () => {
    // Perform any validation or processing here before updating the state
    const numericValue = parseFloat(selectedSolar); // Convert the value to a numeric format
    if (!isNaN(numericValue)) {
      setSelectedSolar(numericValue); // Round the numeric value to 2 decimal places
    }
  };

  const handleChange = (event) => {
    // Allow only numeric values and a dot (for decimals)
    const newValue = event.target.value.replace(/[^0-9.]/g, '');

    if (newValue.length > 8) {
      newValue = newValue.slice(0, 8);
    }
    setSelectedSolar(newValue);
  };

  const handleBlurB = () => {
    // Perform any validation or processing here before updating the state
    const numericValue = parseFloat(selectedBanz); // Convert the value to a numeric format
    if (!isNaN(numericValue)) {
      setSelectedBanz(numericValue); // Round the numeric value to 2 decimal places
    }
  };

  const handleChangeB = (event) => {
    // Allow only numeric values and a dot (for decimals)
    const newValue = event.target.value.replace(/[^0-9.]/g, '');

    if (newValue.length > 8) {
      newValue = newValue.slice(0, 8);
    }
    setSelectedBanz(newValue);
  };

  function formatDateToDDMMYYYYHHmm(date1) {
    const date = new Date(date1);
    const dd = String(date.getDate()).padStart(2, '0'); // Get the day and pad with 0 if needed
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Get the month (0-based) and add 1, then pad with 0 if needed
    const yyyy = date.getFullYear(); // Get the year

    const hh = String(date.getHours()).padStart(2, '0'); // Get the hours and pad with 0 if needed
    const min = String(date.getMinutes()).padStart(2, '0'); // Get the minutes and pad with 0 if needed

    return `${dd}/${mm}/${yyyy} ${hh}:${min}`;
  }

  const excelQueryCellInfo = (args) => {
    if (args.column.field === 'rsm_date') {
      args.value = formatDateToDDMMYYYYHHmm(args.data.rsm_date);
    }

  };


  return (
    <div className="viewcontainer">
      <h1 className="display-5 fw-bold m-3 text-capitalize main-color stroke">{t('Rpt Month Cust Car Movs')}</h1>
      <div className="cretria grid2container pt-2 pb-5 ps-2">
        <div className="grid2container">
          <AutoCompleteComponent id="atcelement"
            enableRtl
            placeholder={t('Select Client')}
            dataSource={dataCards}
            value={selectedClient}
            highlight={true}
            change={handleClientValueChange}
            filterType="Contains"
            fields={{ value: "card_name", text: "card_name" }}
          ></AutoCompleteComponent>
          <DropDownListComponent id="Srcatcelement"
            enableRtl
            placeholder={t('Select Src')}
            dataSource={dataSrcs}
            value={selectedSrc}
            fields={{ value: "value", text: "name" }}
            change={handleSrcValueChange}
          ></DropDownListComponent>
        </div>
        <div className="grid3container">
          <DatePickerComponent format="dd/MM/yyyy"
            enableRtl
            value={selectedDate1}
            change={handleDate1Change}
          ></DatePickerComponent>
          <DatePickerComponent format="dd/MM/yyyy"
            enableRtl
            value={selectedDate2}
            change={handleDate2Change}
          ></DatePickerComponent>
          <Button className="m-1" onClick={handleSearchClick} variant={variant_main} >
            {t('Search')}
          </Button>
        </div>
      </div>
      <div className="cretria grid3container pt-2 pb-5 ps-2">
        <div className="grid4containerBB" >
          <span style={spanStyle}>{t('solar discount')}:</span><input type='number' name='solar' onBlur={handleBlur} onChange={handleChange}
            style={inputStyle} value={selectedSolar} ></input>
          <span style={spanStyle}>{t('banz discount')}:</span><input type='number' name='banz' onBlur={handleBlurB} onChange={handleChangeB}
            style={inputStyle} value={selectedBanz} ></input>
        </div>
        <div className="grid2container">
          <Button className="m-1" onClick={handleUpdateDiscountClick} variant={variant_main} >
            {t('Update')}
          </Button>
        </div>
      </div>
      <GridComponent className="mx-0" enableRtl ref={grid => gridInstance = grid} id="mygrid" name="mygrid" dataSource={data}
        allowPaging={true}
        pageSettings={{ pageSize: 10, pageSizes: true, pageCount: 4 }}
        toolbar={toolbarOptions}
        toolbarClick={toolbarClick.bind(this)}
        excelQueryCellInfo={excelQueryCellInfo}
        allowSorting={true}
        allowTextWrap={true}
        textWrapSettings={{ wrapMode: 'Content' }}
        allowResizing={true}
        autoFit={true}
        allowExcelExport={true}


      >
        <ColumnsDirective>
          {/* <ColumnDirective field='gender' headerText='Gender' textAlign='Right' width='100' />
          <ColumnDirective field='email' headerText='Email' width='150' />
          <ColumnDirective field='nat' headerText='Nationality' /> */}

          <ColumnDirective field='rsm_sn' headerText='sn' textAlign='Right' visible={false} width='5' isPrimaryKey={true} />
          <ColumnDirective field='rsm_date' headerText={t('rsm_date')} format="dd/MM/yyy HH:mm" type="datetime" textAlign='left' enableRtl='false' width='150' />
          <ColumnDirective field='rsm_card_num' headerText={t('rsm_card_num')} textAlign='Right' width='90' />
          <ColumnDirective field='rsm_card_name' headerText={t('rsm_card_name')} textAlign='Right' width='170' />

          <ColumnDirective field='rsm_car_plate' headerText={t('rsm_car_plate')} textAlign='Right' width='150' />
          <ColumnDirective field='rsm_internal' headerText={t('rsm_internal')} width='150' />
          <ColumnDirective field='rsm_car_name' headerText={t('rsm_car_name')} width='150' />

          <ColumnDirective field='rsm_pump' headerText={t('rsm_pump')} width='150' />
          <ColumnDirective field='rsm_tap' headerText={t('rsm_tap')} width='150' />
          <ColumnDirective field='rsm_id' headerText={t('rsm_id')} width='150' />
          <ColumnDirective field='rsm_station' headerText={t('rsm_station')} width='150' />
          <ColumnDirective field='rsm_product' headerText={t('rsm_product')} width='150' />

          <ColumnDirective field='rsm_qnt' headerText={t('rsm_qnt')} width='150' />
          <ColumnDirective field='rsm_total' headerText={t('rsm_total')} width='150' />

          <ColumnDirective field='card_discount' headerText={t('discount')} template={discountTemp} width='65' />

          <ColumnDirective field='rsm_total_final' headerText={t('rsm_total_final')} width='150' />

          <ColumnDirective field='rsm_price' headerText={t('rsm_price')} width='150' />

          <ColumnDirective field='rsm_odometer' headerText={t('rsm_odometer')} width='150' />
          <ColumnDirective field='rsm_distance' headerText={t('rsm_distance')} width='150' />
          <ColumnDirective field='rsm_consumption' headerText={t('rsm_consumption')} width='150' />
          <ColumnDirective field='rsm_emp' headerText={t('rsm_emp')} width='150' />
          <ColumnDirective field='rsm_position' headerText={t('rsm_position')} width='150' />
          <ColumnDirective field='rsm_note' headerText={t('rsm_note')} width='150' />
          <ColumnDirective field='rsm_src' headerText={t('rsm_src')} width='150' />

        </ColumnsDirective>
        <AggregatesDirective>
          <AggregateDirective>
            <AggregateColumnsDirective>
              <AggregateColumnDirective field='rsm_qnt' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
              <AggregateColumnDirective field='rsm_total' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
              <AggregateColumnDirective field='rsm_total_final' type='Sum' format='N2' footerTemplate={footerSum}> </AggregateColumnDirective>
            </AggregateColumnsDirective>
          </AggregateDirective>

        </AggregatesDirective>
        <Inject services={[Page, Sort, Toolbar, Resize, ExcelExport, Aggregate]} />
      </GridComponent>
    </div>

  )
}

export default MonthCustCarMovsScreen
